import React, { useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { RiKey2Fill } from 'react-icons/ri';
import { GiPin } from "react-icons/gi";

import axios from 'axios';
import Loader from '../Loader';
import { jwtDecode } from 'jwt-decode';
import './Home.css'
import toast from 'react-hot-toast';
import { Rings } from 'react-loader-spinner';
import YourAssignments from './YourAssignments';
// import { useFcmToken } from '../context/FcmContext';
const Home = ({ signout, userid }) => {
  const navigate = useNavigate();
  const { organizationId } = useParams()

  const [data, setData] = useState(null)
  // const [loading, setLoading] = useState(true)
  const token = localStorage.getItem('token')
  const [pinnedProjects, setPinnedProjects] = useState([]);
  const [unpinnedProjects, setUnpinnedProjects] = useState([]);
  const [pinnedLoading, setPinnedLoading] = useState(true);
  const [unpinnedLoading, setUnpinnedLoading] = useState(true);
  const [basecampProjects, setBasecampProjects] = useState([]);
  // console.log(pinnedProjects);
  // console.log(data)
  useEffect(() => {
    const savedToken = localStorage.getItem('token');
    if (savedToken) {
      const decodedToken = jwtDecode(savedToken);
      // console.log(decodedToken)
      setData(decodedToken)
      // Check if the token is expired      
    } else {
      console.log('No valid userData found in localStorage');
    }
  }, []);
  // const organizationId = data ? data.organizationId : ""
  const location = useLocation();
  const userDataFromState = location.state?.userData || null;
  const storedUserData = localStorage.getItem('userData');
  const userData = userDataFromState || (storedUserData && JSON.parse(storedUserData));
  const userId = data?._id
  // console.log(userData)
  // console.log("user", userId)
  // console.log("org", organizationId)
  const [date, setDate] = useState(new Date());
  const [projects, setProjects] = useState([]);
  const [isValidOrganization, setIsValidOrganization] = useState(true);
  const [orgName, setOrgname] = useState(null)
  const [refreshProjects, setRefreshProjects] = useState(false);
  const memoisedProject = useMemo(() => {
    return { pinnedProjects, unpinnedProjects };
  }, [pinnedProjects, unpinnedProjects])
  useEffect(() => {
    const handleKeyPress = (event) => {
      // Check for "Ctrl + J" key combination
      if (event.ctrlKey && event.key === "j") {
        event.preventDefault(); // Prevent the default browser action
        navigate("my/preference"); // Navigate to the desired page
      }
    };

    // Attach the event listener
    window.addEventListener("keydown", handleKeyPress);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [navigate]);
  useEffect(() => {
    // const token = userData?.token
    // console.log(token)
    if (!token) {
      // setLoading(true)
      navigate('/');
      return;
    }
    const checkOrganizationValidity = async () => {
      if (organizationId) {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/checkOrganization/${organizationId}`, {
            headers: { 'Authorization': `Bearer ${token}` }
          });
          // Check if the response indicates the organization exists
          // console.log("organization :", response.data)
          if (response.data) {
            setOrgname(response?.data?.name)
            setIsValidOrganization(true); // Organization exists
          } else {
            setIsValidOrganization(false); // Organization does not exist
          }
        } catch (error) {
          console.error('Error checking organization validity:', error);
          setIsValidOrganization(false); // Mark as invalid if an error occurs (like 404)
        } finally {
          // setLoading(false); // Set loading to false when done
        }
      }
      else {
        <Loader />
      }
    };

    checkOrganizationValidity();
  }, [organizationId, navigate, token]);

  // useEffect(() => {
  //   const fetchCentToken = async () => {
  //     try {
  //       const response = await axios.get(`${process.env.REACT_APP_CENTRAL_BASEURL}/userapi/get-token`)
  //       console.log("CENTRAL TOKEN", response.data)
  //     } catch (error) {

  //     }
  //   }
  //   fetchCentToken()
  // }, [])
  // console.log("data in cookie", document.cookie);
  const getCookie = (name) => {
    const cookies = document.cookie.split("; ");
    for (let cookie of cookies) {
      const [key, value] = cookie.split("=");
      if (key === name) return decodeURIComponent(value);
    }
    return null;
  };
  useEffect(() => {
    const baseToken = localStorage.getItem('base_token')

    if (baseToken) {
      const fetchBaseProject = async () => {
        try {
          console.log("url", `${process.env.REACT_APP_API_URL}/api/basecamp/projects`, 'base token', baseToken)

          const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/basecamp/projects`, {
            headers: {
              'Authorization': `Bearer ${baseToken}`
            }
          })
          console.log("BASECAMP PROJECTS", response.data)
          setBasecampProjects(response.data)
        } catch (error) {
          console.error('Error fetching basecamp projects:', error);
        }
      }
      fetchBaseProject()
    }
  }, []);
  useEffect(() => {
    const authToken = getCookie("authToken");
    if (authToken && projects.length === 0 && token && userId && organizationId) {

      const fetchProjects = async () => {
        try {
          setPinnedLoading(true);
          setUnpinnedLoading(true);
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/userprojects`, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'C-Auth-Token': authToken
            },
            params: { userId, organizationId },
            // withCredential: true
            withCredentials: true

          },);
          setProjects(response.data)
          const { pinnedProjects, unpinnedProjects } = response.data;

          setPinnedProjects(pinnedProjects);
          setUnpinnedProjects(unpinnedProjects);
        } catch (error) {
          if (error.response && error.response.status === 401) {
            console.log("No token or token expired, logging out...");
            signout();  // Trigger signout when no token is found or token is expired
            // navigate('*')
          } else {
            console.error("Error fetching projects:", error.message);
          }
        } finally {
          setPinnedLoading(false);
          setUnpinnedLoading(false);
        }
      }

      fetchProjects();
    }
  }, [token, userId, organizationId, projects.length, signout]);

  // useEffect(() => {
  //   const fetchRoles = async (req, res) => {
  //     try {
  //       const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/role-permissions/${userData.user._id}`);
  //       setRolePermissions(response.data);
  //     } catch (err) {
  //       console.error("Error fetching role permissions", err);
  //     }
  //   }
  //   fetchRoles()
  // }, [userData.user._id])
  const onChange = (newDate) => {
    setDate(newDate);
  };
  if (!isValidOrganization) {
    // Redirect to the login page if the organization is invalid
    setTimeout(() => {
      navigate('*'); // Adjust the route to your login page
    }, 1000);
    return <p>Invalid organization ID. Redirecting to the login page...</p>; // Or you can show a message here
  }
  // console.log(rolePermissions)

  const togglePinProject = async (projectId) => {
    // Optimistic state update
    if (pinnedProjects.some(project => project._id === projectId)) {
      const updatedPinned = pinnedProjects.filter(project => project._id !== projectId);
      const projectToUnpin = pinnedProjects.find(project => project._id === projectId);
      setPinnedProjects(updatedPinned);
      setUnpinnedProjects([...unpinnedProjects, projectToUnpin]);
    } else {
      const projectToPin = unpinnedProjects.find(project => project._id === projectId);
      setUnpinnedProjects(unpinnedProjects.filter(project => project._id !== projectId));
      setPinnedProjects([...pinnedProjects, projectToPin]);
    }

    try {
      const res =
        await axios.put(`${process.env.REACT_APP_API_URL}/api/organization/${organizationId}/project/${projectId}/user/${userId}/update-pin`);
      if (res.data.message === "Project pinned successfully") {
        toast.success("Project Pinned")
        setRefreshProjects(!refreshProjects);
      } else {
        toast.success("Project Unpinned")
      }
    } catch (error) {
      console.error("Error updating pin state:", error);
    }
  };

  // useEffect(() => {
  //   console.log("FCM Token in component:", fcmToken);
  // }, [fcmToken]);
  return (


    <div className='Home home-main'>
      {/* <div className='head1'> */}
      <div className="px-2 adminland text-end">
        <Link to="adminland" className="px-2 d-contents adminlandText">
          <RiKey2Fill className='mt-1' style={{ fontSize: "15px" }} />
          Adminland
        </Link>
      </div>

      <h2 className='d-flex justify-content-center fw-bold Organization-name'>
        {/* {orgName} */}
        {orgName || "Loading organization..."}
      </h2>

      {/* </div> */}
      <div className="head2 d-flex justify-content-center mt-3">
        <Link className='act-btn' to="project/new">
          <button className='px-4 py-2 mx-3 action-btn'>
            <span className='fw-normal '>Make a new project</span>
          </button>
        </Link>
        <Link className='act-btn' to='account/enrollments/new'>
          <button className=' px-4 py-2 action-btn'>
            <span className='fw-normal '>Invite People</span>
          </button>
        </Link>
      </div>
      <div className="row  mt-3 col-sm-12">
        <div className='d-flex justify-content-center'>
          <p className='p-tag'>
            <span className='t-color1'>
              Pinned & recent projects below
              {/* · */}
              {/* <Link to="projects/directory" className="mx-1">View all in a list</Link> · <Link to="view-template" className="mx-1">View templates</Link>  */}
              {/* ·  */}
              {/* Press <kbd><Link to="quickaccess" className='text-decoration-none'>Ctrl + J</Link></kbd> anytime to jump */}
            </span>
          </p>
        </div>

        <div className="d-flex justify-content-center mt-0">
          <div className='d-flex flash justify-content-center'>
            {pinnedLoading ? (
              <Rings
                visible={true}
                height="80"
                width="80"
                color="var(--theme-color)"
                ariaLabel="rings-loading"
              />
            ) : (

              memoisedProject.pinnedProjects.map((project, index) => (
                <div className='' key={`${project.id}-${index}`}>
                  <Link
                    to={{
                      pathname: `project/${project._id}`,
                      state: { tools: project.tools },
                    }}
                    className="text-decoration-none project-card"
                  >
                    <div className="card mx-1 w-md-auto">
                      <div className="homecard-body announcement-card p-3">
                        <div className="d-flex flash justify-content-between align-items-start">
                          <div className="w-100">
                            <div className="bg-warning t-color1 ">
                              {project.category}
                            </div>
                            <h5 className="card-title t-color1">{project.name}</h5>
                            <p className="card-text t-color1">{project.description}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>

                  {/* {/ Pin button outside Link to avoid click conflicts /} */}
                  {/* <div className="pin-navai mt-2 mt-md-0"> */}
                  <button
                    className="pin-button pin-navai"
                    onClick={(e) => {
                      e.preventDefault(); // Prevent unwanted page behavior
                      e.stopPropagation(); // Prevent parent navigation
                      togglePinProject(project._id);
                    }}
                  >
                    <GiPin
                      className={`color-pin ${pinnedProjects.includes(project._id) ? 'active-pin' : ''
                        }`}
                    />
                  </button>
                  {/* </div> */}
                </div>
              ))
            )
            }
          </div>

        </div>
        <div className="">
          <div className=" d-flex flex-column flex-md-row flex-wrap justify-content-center">
            <hr className="line" />
            <span className="label text-center recently-visited">RECENTLY VISITED</span>
          </div>
        </div>

        <div className="d-flex flex-md-row flex-wrap justify-content-center mt-0">
          <div className=' d-flex flash justify-content-center'>
            {unpinnedLoading ? (
              <Rings
                visible={true}
                height="80"
                width="80"
                color="var(--theme-color)"
                ariaLabel="rings-loading"
              />) : (


              memoisedProject.unpinnedProjects.map((project, index) => (
                <div className='' key={`${project.id}-${index}`}>
                  <Link
                    to={{
                      pathname: `project/${project._id}`,
                      state: { tools: project.tools },
                    }}
                    className="text-decoration-none project-card"
                  >
                    <div className="card mx-1">
                      <div className="homecard-body announcement-card">
                        <div className="d-flex  justify-content-between align-items-start">
                          <div className="">
                            <div className="bg-warning t-color1">
                              {project.category}
                            </div>
                            <h3 className="card-title t-color1">{project.name}</h3>
                            <p className="card-text t-color1">{project.description}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>

                  {/* {/ Pin button outside Link to avoid click conflicts /} */}
                  {/* <div className="pin-navai mt-2 mt-md-0"> */}
                  <button
                    className="pin-button pin-navai"
                    onClick={(e) => {
                      e.preventDefault(); // Prevent unwanted page behavior
                      e.stopPropagation(); // Prevent parent navigation
                      togglePinProject(project._id);
                    }}
                  >
                    <GiPin
                      className={`pin-icon ${unpinnedProjects.includes(project._id) ? 'active-pin' : ''
                        }`}
                    />
                  </button>
                  {/* </div> */}
                </div>
              ))
            )

            }
          </div>
        </div>
        <div className="">
          <div className=" d-flex flex-column flex-md-row flex-wrap justify-content-center">
            <hr className="line" />
            <span className="label text-center recently-visited">BASECAMP PROJECTS</span>
          </div>
        </div>
        <div className="d-flex flex-md-row flex-wrap justify-content-center mt-0">
          <div className='d-flex flash justify-content-center'>
            {basecampProjects.map((project, index) => (
              <div className='' key={`${project.id}-${index}`}>
                <Link to={`project/${project.id}`} className="text-decoration-none project-card">
                  <div className="card mx-1">
                    <div className="homecard-body announcement-card">
                      <div className="d-flex justify-content-between align-items-start">
                        <div>
                          <div className="bg-warning t-color1">
                            {project.category}
                          </div>
                          <h3 className="card-title t-color1">{project.name}</h3>
                          <p className="card-text t-color1">{project.description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>

                {/* Pin button placed outside the Link */}
                <button
                  className="pin-button pin-navai"
                  onClick={(e) => {
                    e.preventDefault(); // Prevent unwanted page behavior
                    e.stopPropagation(); // Prevent parent navigation
                    togglePinProject(project._id);
                  }}
                >
                  <GiPin className={`pin-icon ${unpinnedProjects.includes(project._id) ? 'active-pin' : ''}`} />
                </button>
              </div>
            ))}
          </div>

        </div>
      </div>

      <div className="d-flex justify-content-center HomeCardCont">
        <div className="card card1 schedule-assignment">
          <div className="card-header  border-0 text-center">
            <div className="schedule-header">
              <span>YOUR SCHEDULE</span>
            </div>
          </div>
          <div className="card-body text-center">
            <div className="calendar mt-2">
              <Calendar
                className=" calendar"
                onChange={onChange}
                value={date}
              />
              <div className="calendar-footer d-flex justify-content-between mt-3 t-color1">
                <p className=' t-color1'>
                  {date.toDateString()}
                </p>
                <p className=' t-color1'>Nothing's on the schedule</p>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="card card1 schedule-assignment">
          <div className="card-header  border-0 text-center">
            <div className="assignments-header">
              <span>YOUR ASSIGNMENTS</span>
            </div>
          </div>

          <div className="card-body text-center mt-5">
            <FontAwesomeIcon icon={faCircleCheck} className="home-shedule" size="2x" />
            <p className="mt-3 text-secondary">
              You don’t have any assignments right now. To-dos and cards assigned to you will show up here.
            </p>
          </div>

        </div> */}
        <YourAssignments userId={userid} />
      </div>
    </div>
  );
};

export default Home;