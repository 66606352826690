import { createContext, useContext, useState } from "react";
import { jwtDecode } from "jwt-decode";
import { fetchProject } from "../utils/fetchproject";
import { useAuth } from "../context/AuthContext";

const ProjectContext = createContext();

export const ProjectProvider = ({ children }) => {
    const [currentProject, setCurrentProject] = useState(null);
    const [isProjectLoading, setIsProjectLoading] = useState(false);
    const [projectError, setProjectError] = useState(null);

    const { signout } = useAuth(); // Get signout function

    // Decode userData from JWT stored in localStorage
    const getUserData = () => {
        const token = localStorage.getItem("token");
        if (token) {
            try {
                return jwtDecode(token);
            } catch (error) {
                console.error("Invalid token:", error);
            }
        }
        return null;
    };

    // Function to fetch project data
    const fetchCurrentProject = async (projectId, organizationId) => {
        const userData = getUserData();
        const token = localStorage.getItem("token");

        if (!token || !userData) {
            setProjectError("No token found. Please log in.");
            return;
        }

        setIsProjectLoading(true);
        try {
            const project = await fetchProject({ id: projectId, organizationId, token, signout });
            // console.log("context project data ", project);
            setCurrentProject(project);
        } catch (err) {
            setProjectError(err.message);
        } finally {
            setIsProjectLoading(false);
        }
    };
    // console.log("current contxt project", currentProject)
    return (
        <ProjectContext.Provider
            value={{
                currentProject,
                fetchCurrentProject,
                isProjectLoading,
                projectError,
            }}
        >
            {children}
        </ProjectContext.Provider>
    );
};

// Custom hook to use the project context
export const useProjectContext = () => useContext(ProjectContext);
