import './App.css';
import axios from 'axios';
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState, useEffect, Suspense } from 'react';
import { jwtDecode } from 'jwt-decode';
import { Toaster } from 'react-hot-toast';

import {
  authRoutes,
  projectRoutes,
  adminRoutes,
  myStuffRoutes,
  homeRoutes,
  adminlandRoutes,
  activityRoutes,
  profileRoutes,
  inviteRoutes,
  roleRoutes,
  otherRoutes
} from './Routes/Routes.js'
import HomeNav from './components/HomePageComponents/HomeNav';
import Loader from './Loader.js';
import ProtectedRoute from './Auth/ProtectedRoute.js';
import BodyStyleManager from './components/BodyStyleManager.js';
import { useCookies } from 'react-cookie';
import Home from './components/Home.js';
// import { Cookies } from 'js-cookie';
import { AuthProvider } from './context/AuthContext.js'
import { messaging } from "./components/FireBase/firebase.js";
import { getToken, onMessage, deleteToken } from "firebase/messaging";
import { FcmProvider } from './context/FcmContext.js';
import { ProjectProvider } from './context/ProjectContext.js';
function App() {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const navigate = useNavigate();
  const [userPreferences, setUserPreferences] = useState(null);
  const [token, setToken] = useState(null);
  const [fcmToken, setFcmToken] = useState(null);
  const [data, setData] = useState(null)
  const userId = data?._id
  const [cookies, setCookie] = useCookies(["authToken"]);
  const PREFERENCE_KEY = "userPreferences";
  useEffect(() => {
    const token = localStorage.getItem('token')
    if (token) {
      setIsSignedIn(true);
    } else {
      setIsSignedIn(false);
    }
  }, [token]);
  useEffect(() => {
    navigator.serviceWorker.register("/firebase-messaging-sw.js")
      .then((registration) => {
        // console.log("Service Worker Registered:", registration);
      })
      .catch((error) => {
        console.error("Service Worker Registration Failed:", error);
      });
  }, []);

  const [notification, setNotification] = useState(null);
  useEffect(() => {
    const requestPermission = async () => {
      try {
        const permission = await Notification.requestPermission();
        console.log("Notification permission", permission)
        if (permission === "granted") {
          const currentToken = await getToken(messaging, {
            vapidKey: "BDbKP12RRP1f2EVuQOSRl7mgov7gHjOQVtpjI1r6NEgtUYEhr-0wrx9SMyNoqluwLwKAahT2X2moZnXXdz9_tKs",
          });
          if (currentToken) {
            setFcmToken(currentToken);
            // console.log("FCM Token:", currentToken);
            if (data?._id) {
              saveFcmToken(data._id, currentToken);
            }
          } else {
            console.log("No FCM token received.");
          }
        } else {
          console.log("Notification permission denied.");
        }
      } catch (error) {
        console.error("Error getting permission for notifications", error);
      }
    };

    requestPermission();

    // Listen for incoming messages when the app is in the foreground
    const unsubscribe = onMessage(messaging, (payload) => {
      console.log("Message received:", payload);
      setNotification(payload.notification);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const unsubscribe = onMessage(messaging, (payload) => {
      console.log("🔥 Message received:", payload);
      setNotification(payload.data);  // Update to handle data messages
    });

    return () => unsubscribe();
  }, []);

  const saveFcmToken = async (userId, fcmToken) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/save-fcm-token`, { userId, fcmToken });
      console.log("FCM Token saved:", response.data);
    } catch (error) {
      console.error("Error saving FCM token:", error);
    }
  }
  const signin = (token) => {
    setIsSignedIn(true);
    const decodedToken = jwtDecode(token);
    setData(decodedToken)
    localStorage.setItem('token', token);
    if (decodedToken?._id && fcmToken) {
      saveFcmToken(decodedToken._id, fcmToken);
    }
  };

  const clearTeamnexCookie = (cookieName) => {
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; secure; sameSite=None;`;
  };
  const signout = async () => {
    console.log('Logging out...');
    const isCookiesCleared = await clearCookie();
    if (!isCookiesCleared) {
      console.warn('Proceeding with logout despite cookie clearing failure.');
    }
    clearTeamnexCookie("authToken");
    setIsSignedIn(false);
    setUserPreferences(null)
    document.documentElement.style.removeProperty('--theme-color');
    document.documentElement.removeAttribute('data-appearance');
    localStorage.removeItem('token');
    localStorage.removeItem('userData');
    localStorage.removeItem(`preferences_${userId}_${organizationId}`);
    navigate('/', { replace: true });
    window.history.replaceState({}, '', '/');
    localStorage.clear()
  };
  const location = useLocation();
  // console.log("data", data)
  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
  };

  // console.log("from cookie", getCookie('authToken'))
  // useEffect(() => {
  //   const token = getCookie('authToken');

  //   if (!token) {
  //     console.warn("No auth token found, skipping API call");
  //     return;
  //   }

  //   const refreshAPI = async () => {
  //     try {
  //       const response = await axios.post(
  //         `${process.env.REACT_APP_CENTRAL_BASEURL}/userapi/refresh`,
  //         { authToken: token },
  //         { withCredentials: true }
  //       );
  //       console.log("Common API response:", response.data);
  //       // Handle API response here (e.g., update state)
  //     } catch (error) {
  //       console.error("Error fetching common API:", error);
  //     }
  //   };

  //   refreshAPI();
  // }, [location.pathname]);
  const excludePaths = [
    "/:organizationId/accept-invite/:token",
    "/:organizationId/accept-invite/:token/login",
    "/:organizationId/accept-invite/:token/new-login",
    "/reset-password/:token",
    "/login",
    "/select-organization",
    "/not-verified",
    "/admin",
    "/admin-dashboard",
    "/admin-dashboard/organizations",
    "/admin-dashboard/users",
    "/admin-dashboard/settings",
    "/admin-dashboard/organizations/:organizationId",
    "/signup",
    "/auth/callback",
    "/"
  ];
  const isExcludedPath = (path) => {
    return excludePaths.some((excludedPath) => {
      const regex = new RegExp(`^${excludedPath.replace(/:[^/]+/g, "[^/]+")}$`);
      return regex.test(path);
    });
  };
  useEffect(() => {

    if (isExcludedPath(location.pathname)) {
      // console.log("Path excluded:", location.pathname);
      return;
    }
    const setCookieAndToken = async (user) => {
      // console.log("user", user)
      const postData = { email_id: user, loginfrom: 4 };
      const setCookieResponse = await axios.post(
        `${process.env.REACT_APP_CENTRAL_BASEURL}userapi/set_cookie`,
        postData,
        { headers: { "Content-Type": "application/json" }, withCredentials: true }
      );
      // console.log("set cookie response", setCookieResponse.data)
      if (setCookieResponse.data.status === "true") {
        const getTokenResponse = await axios.get(
          `${process.env.REACT_APP_CENTRAL_BASEURL}userapi/get-token`,
          { withCredentials: true }
        );

        // console.log("get token response", getTokenResponse.data)
        if (getTokenResponse.data.status === "true") {
          setCookie("authToken", getTokenResponse.data.authToken, {
            path: "/", maxAge: 3600, secure: true, sameSite: "none",
          });
          // console.log("get token:", getTokenResponse.data.authToken)
          return getTokenResponse.data.authToken;
        } else {
          throw new Error("Failed to get token");
        }
      } else {
        throw new Error("Failed to set cookie");
      }
    };
    setCookieAndToken(data?.email)
  }, [location.pathname, data, setCookie])
  useEffect(() => {
    const savedToken = localStorage.getItem('token');
    if (savedToken) {
      setToken(savedToken);
      const decodedToken = jwtDecode(savedToken);
      setData(decodedToken)
    }
    // else {
    //   // console.log('No valid userData found in localStorage');
    // }
  }, [location.pathname]);

  useEffect(() => {
    const savedToken = localStorage.getItem('token');
    if (savedToken) {
      const decodedToken = jwtDecode(savedToken);
      const currentTime = Date.now() / 1000;  // Get current time in seconds

      if (decodedToken.exp < currentTime) {

        signout();
      } else {
        setToken(savedToken);
      }
    } else {
      // console.log('No valid userData found in localStorage');
    }
  }, []);
  const [userData, setUserData] = useState(null);

  const [rolePermissions, setRolePermissions] = useState(null);

  const currentOrg = localStorage.getItem("currentOrg")
  useEffect(() => {
    if (!userId && !currentOrg) return;
    const fetchRoles = async () => {
      try {
        if (userId && currentOrg) {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/organization/${currentOrg}/userId/${userId}/userCurrentRole`);
          // console.log("current user role ", response.data)
          setRolePermissions(response.data.role);
          // console.log("current user role ", response.data.role)
        }

      } catch (err) {
        console.error("Error fetching role permissions", err);
      }
    };

    fetchRoles();
  }, [userId, currentOrg]);

  // console.log(rolePermissions)
  useEffect(() => {
    const storedUserData = localStorage.getItem('userData');
    if (storedUserData) {
      try {
        const parsedUserData = JSON.parse(storedUserData);
        setUserData(parsedUserData);
      } catch (error) {
        console.error("Error parsing userData:", error);
      }
    } else {
      // console.log("No valid userData found in localStorage");
    }
  }, [isSignedIn]);

  const organizationId = data?.organizationId


  console.log("orgId", organizationId)
  // useEffect(() => {

  //   if (isSignedIn && userId && organizationId) {
  //     axios.get(`${process.env.REACT_APP_API_URL}/api/get-preference`, { params: { userId, organizationId } })
  //       .then(response => {
  //         setUserPreferences(response.data.preferences);
  //         const { colorTheme, appearanceMode } = response.data.preferences;
  //         document.documentElement.style.setProperty('--theme-color', colorTheme);
  //         document.documentElement.setAttribute('data-appearance', appearanceMode);
  //         // console.log("getting themes ", response.data.preferences)
  //       })
  //       .catch(error => console.log('Error fetching preferences:', error));

  //   }
  // }, [organizationId, userId, isSignedIn]);

  useEffect(() => {
    console.log("isSignedIn in frontend", isSignedIn, userId, organizationId)
    if (isSignedIn && userId && organizationId) {
      // Try to get preferences from localStorage
      const storedPreferences = localStorage.getItem(`preferences_${userId}_${organizationId}`);

      if (storedPreferences) {
        const preferences = JSON.parse(storedPreferences);
        setUserPreferences(preferences);
        document.documentElement.style.setProperty('--theme-color', preferences.colorTheme);
        document.documentElement.setAttribute('data-appearance', preferences.appearanceMode);
      } else {
        // Only fetch from API if not in localStorage
        axios.get(`${process.env.REACT_APP_API_URL}/api/get-preference`, { params: { userId, organizationId } })
          .then(response => {
            const { colorTheme, appearanceMode } = response.data.preferences;
            const preferences = { colorTheme, appearanceMode };
            setUserPreferences(preferences);
            document.documentElement.style.setProperty('--theme-color', preferences.colorTheme);
            document.documentElement.setAttribute('data-appearance', preferences.appearanceMode);
            // Store preferences in localStorage
            localStorage.setItem(`preferences_${userId}_${organizationId}`, JSON.stringify(preferences));
          })
          .catch(error => console.log('Error fetching preferences:', error));
      }
    }
  }, [organizationId, userId, isSignedIn]);

  // console.log("FCM TOKEN", fcmToken)
  const doesPathMatch = (currentPath, templatePath) => {
    const currentSegments = currentPath.split("/").filter(Boolean);
    const templateSegments = templatePath.split("/").filter(Boolean);

    if (currentSegments.length !== templateSegments.length) {
      return false;
    }

    return templateSegments.every((segment, index) => {
      return segment.startsWith(":") || segment === currentSegments[index];
    });
  };

  const shouldShowHomeNav = isSignedIn && location.pathname !== "/loading" && !excludePaths.some(path => doesPathMatch(location.pathname, path));

  // console.log("user roles , ", rolePermissions?.pageAuth?.Adminland)
  // const organizationId()
  const clearCookie = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_CENTRAL_BASEURL}/userapi/clear_cookie`, {
        withCredentials: true,
      })
      console.log('Cookies cleared successfully:', response.data);
      return true; // Indicate success
    } catch (error) {
      console.error('Error while clearing cookies:', error);
      return false; // Indicate failure
    }
  }

  return (
    <AuthProvider signout={signout} rolePermissions={rolePermissions}>
      {/* <FcmProvider> */}
      <ProjectProvider>

        <>
          {shouldShowHomeNav && <HomeNav onLogout={signout} userData={userData} userid={userId} currentUserId={userId} />}

          <Toaster />
          <BodyStyleManager />

          <Suspense fallback={<Loader />}>
            <Routes>
              <Route path="/admin" element={<adminRoutes.Adminlogin />} />
              <Route path="/admin-dashboard/*" element={<adminRoutes.AdminDashboard />} />


              <Route path="/signup" element={<authRoutes.Signup />} />
              <Route path="/loading" element={<authRoutes.LoginLoader />} />
              <Route path="/forgot-password" element={<authRoutes.ForgotPass />} />
              <Route path='/verify-email/:verifyId' element={<authRoutes.VerifyMail />} />
              <Route path='/not-verified' element={<authRoutes.NotVerified />} />
              <Route path="/organization-paused" element={<authRoutes.PauseOrganization />} />
              <Route path="/reset-password/:token" element={<authRoutes.ResetPassword />} />
              <Route path="/select-organization" element={<authRoutes.SelectOrganization onSignIn={signin} />} />
              <Route path="/auth/callback" element={<authRoutes.BasecampAutHandler setIsSignedIn={setIsSignedIn} isSignedIn={isSignedIn} onSignIn={signin} />} />

              {/* Invite Routes */}
              <Route path="/:organizationId/accept-invite/:token" element={<inviteRoutes.InvitationHandler />} />
              <Route path="/:organizationId/accept-invite/:token/login" element={<inviteRoutes.InviteExistLogin />} />
              <Route path="/:organizationId/accept-invite/:token/new-login" element={<inviteRoutes.InviteNewuser />} />

              {data ?
                <>
                  <Route path="/" element={isSignedIn ? <Navigate to={`/${organizationId}`} /> : <authRoutes.Login onSignIn={signin} />} />
                  <Route path='/:organizationId' element={<Home userData={userData} signout={signout} />} />


                  {/* Adminland Routes */}
                  <Route path="/:organizationId/adminland" element={
                    <ProtectedRoute isSignedIn={isSignedIn}>
                      {rolePermissions?.pageAuth?.Adminland === "Allow" ? < adminlandRoutes.Adminland /> : <adminlandRoutes.Adminland1 />}
                    </ProtectedRoute>} />
                  <Route path="/:organizationId/quickaccess" element={<ProtectedRoute isSignedIn={isSignedIn}><homeRoutes.QuickAccess /></ProtectedRoute>} />
                  <Route path="/administrators" element={<adminlandRoutes.Administrators />} />
                  <Route path="/changecontent" element={<adminlandRoutes.Changecontent />} />
                  <Route path="/renameprojecttools" element={<adminlandRoutes.RenameProjectTools />} />
                  <Route path="/messagecategories" element={<adminlandRoutes.ChangeMessageCategories />} />
                  <Route path="/changepings" element={<adminlandRoutes.ChangePings />} />
                  <Route path="/editprojectsettings" element={<adminlandRoutes.EditProjectSettings />} />
                  <Route path="/planselection" element={<adminlandRoutes.PlanSelection />} />
                  <Route path="/managepublicitems" element={<adminlandRoutes.ManagePublicItems />} />
                  <Route path="/cancelaccount" element={<adminlandRoutes.CancelAccount />} />
                  <Route path="/mergepeople" element={<adminlandRoutes.MergePeople />} />
                  <Route path="/moveproject" element={<adminlandRoutes.MoveProject />} />
                  <Route path="/managegroups" element={<adminlandRoutes.MoveProject />} />
                  <Route path="/managecompanies" element={<adminlandRoutes.ManageCompanies />} />
                  <Route path="/archive-and-delete-project" element={<adminlandRoutes.ArchiveAndDeleteProject />} />
                  <Route path="/editproject" element={<adminlandRoutes.EditProject />} />
                  <Route path="/publiclink" element={<adminlandRoutes.PublicLink />} />
                  <Route path="/two-factor-authentication" element={<adminlandRoutes.TwoFactorAuthentication />} />
                  <Route path="/ping-and-chat-history" element={<adminlandRoutes.PingAndChatHistory />} />
                  <Route path="/add-remove-acc" element={<adminlandRoutes.AddRemoveAcc />} />
                  <Route path="/account-rename" element={<adminlandRoutes.AccountRename userData={userData} />} />
                  <Route path="/trash" element={<adminlandRoutes.Trash />} />
                  <Route path="/reassignment" element={<adminlandRoutes.Reassignment />} />
                  <Route path="/projectAccess" element={<adminlandRoutes.ProjectAccess />} />
                  <Route path="/manageStorage" element={<adminlandRoutes.ManageStorage />} />
                  <Route path="/:organizationId/chat/:chatId" element={<myStuffRoutes.PrivateChatinPing userId={userId} />} />
                  <Route path="/export-data" element={<adminlandRoutes.ExportData />} />




                  <Route path="/:organizationId/project/:id" element={<ProtectedRoute isSignedIn={isSignedIn}><projectRoutes.ProjectCard userId={userId} /></ProtectedRoute>} />
                  <Route path="/:organizationId/project/:id/setuptoolsthisproject" element={<ProtectedRoute isSignedIn={isSignedIn}><projectRoutes.SetUpToolThisProject /></ProtectedRoute>} />
                  <Route path="/:organizationId/project/:id/people/users/edit" element={<otherRoutes.SetUpPeople />} />
                  <Route path="/:organizationId/project/:id/people/users/*" element={<otherRoutes.AddPeopleToProject />} />
                  <Route path="/:organizationId/project/new" element={<ProtectedRoute isSignedIn={isSignedIn}><homeRoutes.Createproject /></ProtectedRoute>} />
                  <Route path="/:organizationId/project/:id/chat" element={<projectRoutes.Campfire userId={userId} />} />
                  <Route path="/:organizationId/project/:id/messageBoard/newMessage" element={<ProtectedRoute isSignedIn={isSignedIn}><projectRoutes.NewMessage /></ProtectedRoute>} />
                  <Route path="/:organizationId/project/:id/docsfiles/:folderId?/newDocument" element={<ProtectedRoute isSignedIn={isSignedIn}><projectRoutes.NewDocument /></ProtectedRoute>} />
                  <Route path="/:organizationId/project/:id/messageBoard" element={<projectRoutes.MessageBoard />} />
                  <Route path="/:organizationId/project/:id/messageBoard/message/:messageId" element={<projectRoutes.MessageShow />} />
                  {/* <Route path="/subscriberlist" element={<projectRoutes.SubscriberList />} /> */}
                  <Route path="/:organizationId/projects/directory" element={<ProtectedRoute isSignedIn={isSignedIn}><otherRoutes.ViewAllList /></ProtectedRoute>} />
                  <Route path="/projectschedule" element={<projectRoutes.ProjectSchedule />} />
                  <Route path="/:organizationId/project/:id/todos" element={<projectRoutes.ToDos userId={userId} />} />
                  <Route path="/:organizationId/project/:id/todos/:taskid" element={<projectRoutes.Task userId={userId} />} />
                  <Route path="/:organizationId/project/:id/taskList/:taskListId" element={<projectRoutes.TaskList userId={userId} />} />
                  <Route path="/:organizationId/project/:id/schedule" element={<ProtectedRoute isSignedIn={isSignedIn}><projectRoutes.Schedule /></ProtectedRoute>} />
                  <Route path="/:organizationId/project/:id/schedule/:scheduleId" element={<ProtectedRoute isSignedIn={isSignedIn}><projectRoutes.ViewSchedule /></ProtectedRoute>} />
                  <Route path="/:organizationId/project/:id/revocation" element={<ProtectedRoute isSignedIn={isSignedIn}><projectRoutes.LeaveThisProject /></ProtectedRoute>} />
                  <Route path="/:organizationId/project/:id/cardtable/:cardId" element={<projectRoutes.ViewCard />} />
                  <Route path="/:organizationId/project/:id/cardtable/triage" element={<projectRoutes.TriageCard userId={userId} />} />

                  {/*  Subscriber list dynamic page*/}
                  <Route path="/:organizationId/project/:id/:moduleType/:moduleId/subscribers" element={<projectRoutes.SubscriberEdit userId={userId} />} />

                  <Route path="/:organizationId/project/:id/autocheckins" element={<projectRoutes.Autocheckin />} />
                  <Route path="/:organizationId/project/:id/autocheckins/:questionId" element={<projectRoutes.ViewQuestion userId={userId} />} />
                  <Route path="/:organizationId/project/:id/autocheckins/:questionId/answer" element={<projectRoutes.AddAnswer userId={userId} />} />
                  <Route path="/:organizationId/project/:id/autocheckins/questions/new" element={<projectRoutes.AutocheckinNewQuestion userId={userId} />} />
                  <Route path="/:organizationId/project/:id/docsfiles/:folderId?" element={<projectRoutes.DocsFiles />} />
                  <Route path="/:organizationId/project/:id/docsfiles/:folderId?/document/:docId" element={<projectRoutes.ViewDocument />} />
                  <Route path="/:organizationId/project/:id/docsfiles/:folderId?/file/:fileId" element={<projectRoutes.ViewFiles />} />
                  <Route path="/:organizationId/project/:id/docsfiles/:folderId?/file/:fileId/new/version" element={<projectRoutes.ReplaceFile />} />
                  <Route path="/:organizationId/project/:id/docsfiles/:folderId?/file/:fileId/all-version" element={<projectRoutes.AllVersions />} />
                  <Route path="/invite" element={<otherRoutes.AddEmployees />} />
                  <Route path="/:organizationId/project/:id/emailforward" element={<projectRoutes.EmailForward />} />
                  <Route path="/:organizationId/project/:id/cardtable" element={<projectRoutes.CardTable userId={userId} />} />
                  <Route path="/justfollow" element={<otherRoutes.JustFollow />} />
                  <Route path="/projectsettings" element={<otherRoutes.ProjectSettings />} />

                  <Route path="/:organizationId/my/preference" element={<ProtectedRoute isSignedIn={isSignedIn}><profileRoutes.Preferences userPreferences={userPreferences} setUserPreferences={setUserPreferences} /></ProtectedRoute>} />
                  <Route path="/:organizationId/my/profile" element={<ProtectedRoute isSignedIn={isSignedIn}><profileRoutes.PersonalCard /></ProtectedRoute>} />
                  <Route path="/:organizationId/my/devices" element={<ProtectedRoute isSignedIn={isSignedIn}><profileRoutes.MyDevices /></ProtectedRoute>} />
                  <Route path="/:organizationId/my/out_of_office" element={<profileRoutes.OutOfOffice />} />
                  <Route path="/:organizationId/my/notifications/settings/edit" element={<ProtectedRoute isSignedIn={isSignedIn}><profileRoutes.NotificationSetting /></ProtectedRoute>} />

                  <Route path="/:organizationId/my/assignments" element={<ProtectedRoute isSignedIn={isSignedIn}><myStuffRoutes.Assignment /></ProtectedRoute>} />
                  <Route path="/:organizationId/my/bookmarks" element={<ProtectedRoute isSignedIn={isSignedIn}><myStuffRoutes.Bookmark /></ProtectedRoute>} />
                  <Route path="/:organizationId/my/schedules" element={<ProtectedRoute isSignedIn={isSignedIn}><myStuffRoutes.Schedule /></ProtectedRoute>} />
                  <Route path="/:organizationId/my/boosts" element={<ProtectedRoute isSignedIn={isSignedIn}><myStuffRoutes.Boosts /></ProtectedRoute>} />
                  <Route path="/:organizationId/my/drafts" element={<ProtectedRoute isSignedIn={isSignedIn}><myStuffRoutes.Drafts /></ProtectedRoute>} />
                  <Route path="/:organizationId/activity" element={<ProtectedRoute isSignedIn={isSignedIn}><myStuffRoutes.Activity /></ProtectedRoute>} />

                  <Route path="/latest-activity" element={<activityRoutes.Latestactivity />} />
                  <Route path="/someones-activity" element={<activityRoutes.SomeonesActivity />} />
                  <Route path="/someones-assignment" element={<activityRoutes.SomeonesAssignment />} />
                  <Route path="/mission-control" element={<activityRoutes.Missioncontrol />} />
                  <Route path="/to-dos" element={<activityRoutes.Todos />} />
                  <Route path="/unassignedwork" element={<activityRoutes.UnassignedWork />} />
                  <Route path="/upcomingdates" element={<activityRoutes.UpcomingDates />} />
                  <Route path="/timesheet" element={<activityRoutes.TimeSheet />} />
                  <Route path="/overdue" element={<activityRoutes.Overdue />} />

                  <Route path="/:organizationId/account/enrollments/new" element={<inviteRoutes.InvitePeople userData={userData} />} />
                  <Route path="/:organizationId/account/enrollments/new/coworkers-account" element={<inviteRoutes.CoworkersAccount userData={userData} />} />
                  <Route path="/:organizationId/account/enrollments/new/coworkers-account/success" element={<inviteRoutes.InviteSuccess userData={userData} />} />
                  <Route path="/:organizationId/account/enrollments/new/coworkers-account/exist" element={<inviteRoutes.InviteExist userData={userData} />} />
                  <Route path="/invite-link" element={<inviteRoutes.InviteLink />} />
                  <Route path="/:organizationId/view-template" element={<ProtectedRoute isSignedIn={isSignedIn}><otherRoutes.ViewTemplate /></ProtectedRoute>} />
                  <Route path="/project-template" element={<otherRoutes.ProjectTemplate />} />
                  <Route path="/todo-list-lemplate" element={<otherRoutes.ToDoListTemplate />} />


                  <Route path="/:organizationId/adminland/add-roles" element={<roleRoutes.CreateRole />} />
                  <Route path="/:organizationId/adminland/add-roles/new-role" element={<roleRoutes.SetPermission />} />
                  <Route path="/:organizationId/adminland/add-roles/view-permission/:roleId" element={<roleRoutes.ViewPermission />} />
                  <Route path="/:organizationId/adminland/add-roles/edit-permission/:roleId" element={<roleRoutes.EditPermission />} />
                  <Route path="/:organizationId/lineup" element={<ProtectedRoute isSignedIn={isSignedIn}><myStuffRoutes.LineUp /></ProtectedRoute>} />
                  <Route path="*" element={<otherRoutes.NotfoundPage />} />

                </>
                : (

                  <Route path="*" element={<authRoutes.Login onSignIn={signin} />} />
                )}

            </Routes>
          </Suspense>


        </>
      </ProjectProvider>

      {/* </FcmProvider> */}
    </AuthProvider>
  );
}

export default App;