import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyA2AhEyhcifNNEDzKX_TGMheN6YFNnCOtU",
    authDomain: "teamnex-d79f9.firebaseapp.com",
    projectId: "teamnex-d79f9",
    storageBucket: "teamnex-d79f9.firebasestorage.app",
    messagingSenderId: "23402612781",
    appId: "1:23402612781:web:313a1e20a47812b99010a4"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
// console.log("Firebase App Initialized:", firebaseApp);

const messaging = getMessaging(firebaseApp);
export { messaging };
