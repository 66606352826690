import React, { useState, useEffect } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faBars, faSearch, faSmile } from '@fortawesome/free-solid-svg-icons';
import { Dropdown, DropdownToggle, DropdownMenu } from 'react-bootstrap';
import { IoBookmark } from 'react-icons/io5';
import { GoClockFill } from "react-icons/go";
import { MdRocketLaunch } from 'react-icons/md';
import { AiFillFileText, AiFillSchedule } from "react-icons/ai";
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import './HomeNav.css'
import OrganizationDropdown from './OrganizationDropdown';
import toast from 'react-hot-toast';
import { Modal } from "react-bootstrap";
import PingsDropDown from '../PingsDropDown';
import { SiVerizon } from 'react-icons/si';
import HeyDropdown from '../HeyDropdown';

const HomeNav = ({ onLogout, currentUserId }) => {
  const [showorgDropdown, setShoworgDropdown] = useState(false)
  const [data, setData] = useState(null)
  const organizationId = localStorage.getItem('currentOrg');
  // console.log("org id ", organizationId)
  // const {organizationId} = useParams()
  useEffect(() => {
    const savedToken = localStorage.getItem('token');
    if (savedToken) {
      const decodedToken = jwtDecode(savedToken);
      setData(decodedToken)
      // Check if the token is expired      
    } else {
      console.log('No valid userData found in localStorage');
    }
  }, []);
  // const { user, error } = useContext(UserContext)
  const navigate = useNavigate();
  // const toggle = () => setDropdownOpen((prevState) => !prevState);
  // const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedAnyone, setSelectedAnyone] = useState('');

  // const [dropdownPingOpen, setDropdownPingOpen] = useState(false);
  // const toggleDropdown = () => {
  //   setDropdownPingOpen(!dropdownPingOpen);
  // };
  // const organizationId = userData ? userData.user.organizationId._id : ""
  // const location = useLocation();
  // const userDataFromState = location.state?.userData || null;
  const storedUserData = localStorage.getItem('userData');
  const userData = JSON.parse(storedUserData)
  // const organizationId = userData?.user?.organizationId?._id
  // console.log(organizationId)

  const [pingUsers, setPingusers] = useState([])
  const [pingLoading, setPingLoading] = useState(false)

  useEffect(() => {
    const savedToken = localStorage.getItem('token');
    if (savedToken) {
      const decodedToken = jwtDecode(savedToken);
      setData(decodedToken)
      // console.log(decodedToken)
      // setUserorganizations(decodedToken?.organizations)
      // Check if the token is expired      
    } else {
      console.log('No valid userData found in localStorage');
    }
  }, [])

  useEffect(() => {
    const fetchOrganizationUser = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/organization/members/${organizationId}`);
        // console.log("mems", response.data);
        const options = response.data.members
          .filter(member => member.user && member.user._id !== currentUserId)
          .map(member => ({
            value: member.user._id,
            label: member.user.username,
            profilePhoto: member.user.profilePhoto
          }));
        setPingusers(options);
        // console.log(options);
      } catch (error) {
        console.error('Error fetching members:', error);
        console.log(error.message);
      }
    };
    fetchOrganizationUser();
  }, [organizationId, currentUserId]);
  const [userorganizations, setUserorganizations] = useState([])
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (!currentUserId) return;

    const fetchOrganizations = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/get-org/${currentUserId}`);
        if (!response.ok) throw new Error("Failed to fetch organizations");

        const data = await response.json();
        setUserorganizations(data.organizations || []); // Adjust based on API response
      } catch (error) {
        console.error("Error fetching organizations:", error);
        setUserorganizations([]); // Set empty on failure
      }
      setIsLoading(false);
    };

    fetchOrganizations();
  }, [currentUserId, organizationId]);
  // console.log("userOrgs", userorganizations)
  const [selectedUsers, setSelectedUsers] = useState([]);

  const handleCreate = (options) => {
    const formattedUsers = options.map(user => ({
      _id: user.value, // Ensure it matches `pingUsers`
      name: user.label
    }));
    console.log("Formatted Selected Users:", formattedUsers);
    setSelectedUsers(formattedUsers);
  };



  const getInitials = (name = "") => {
    if (!name) return "?"; // Return a fallback value
    const nameArray = name.split(" ");
    const initials = nameArray.map(n => n.charAt(0)).join("").toUpperCase();
    return initials;
  };





  const formatOptionLabel = ({ label, profilePhoto }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {profilePhoto ? (
        <img
          src={`${process.env.REACT_APP_API_URL}${profilePhoto}`}
          alt='as'
          style={{ width: 24, height: 24, borderRadius: '50%', marginRight: 10 }}
        />
      ) : (
        <span
          style={{
            width: 24,
            height: 24,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
            backgroundColor: '#4caf50', // Background color for initials
            color: '#fff', // Text color for initials
            marginRight: 10,
            fontSize: '12px',
          }}
        >
          {label ? getInitials(label) : "?"}
        </span>
      )}
      {label}
    </div>
  );

  const handleChange = (event) => {
    setSelectedAnyone(event.target.value);
  };
  const [selecteEverything, setSelectedEverything] = useState('');
  const handleChanges = (event) => {
    setSelectedEverything(event.target.value);
  };

  //Ping chat creation Integration

  // const handlePing = async () => {
  //   if (selectedUsers.length === 0) {
  //     alert("Please select at least one user.");
  //     return;
  //   }
  //   const token = localStorage.getItem('token');
  //   if (!token) {
  //     console.error('Token is undefined or not found in localStorage');
  //     return;
  //   }
  //   try {
  //     setPingLoading(true)
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_API_URL}/api/organization/${organizationId}/createChat`,
  //       {
  //         userIds: selectedUsers.map((user) => user.value),
  //         chatName: selectedUsers.length > 1 ? "New Group" : undefined,
  //       },
  //       {
  //         headers: { Authorization: `Bearer ${token}` }, // Include token
  //       }
  //     );

  //     console.log("Chat Created:", response.data);

  //     // Navigate to chat
  //     if (response.data && response.data._id) {
  //       navigate(`${organizationId}/chat/${response.data._id}`, {
  //         state: {
  //           userName:
  //             selectedUsers.length > 1
  //               ? "defaultName"
  //               : selectedUsers[0]?.name,
  //           userProfilePhoto:
  //             selectedUsers.length > 1 ? null : selectedUsers[0].profilePhoto,

  //         },
  //       });
  //     }
  //   } catch (error) {
  //     console.error("Error creating chat:", error);
  //     alert("Failed to create chat. Please try again.");
  //   } finally {
  //     setPingLoading(false)

  //   }
  // };
  // console.log("selected ", selectedUsers)
  const Everything = [
    { value: '', label: 'Search Everything' },
    { value: 'Card tables', label: 'Card tables' },
    { value: 'Chats', label: 'Chats' },
    { value: 'Check-ins', label: 'Check-ins' },
    { value: 'Client emails', label: 'Client emails' },
    { value: 'Comments', label: 'Comments' },
    { value: 'Documents', label: 'Documents' },
    { value: 'Events', label: 'Events' },
    { value: 'Files', label: 'Files' },
    { value: 'Folders', label: 'Folders' },
    { value: 'Forwarded emails', label: 'Forwarded emails' },
    { value: 'Messages', label: 'Messages' },
    { value: 'Pings', label: 'Pings' },
    { value: 'To-dos', label: 'To-dos' },
  ];
  const Anyone = [
    { value: '', label: 'by Anyone' },
    { value: 'Arul Kumar V', label: 'Arul Kumar V' },
    { value: 'Arun Kumar', label: 'Arun Kumar' },
    { value: 'ArunKumar AD', label: 'ArunKumar AD' },
    { value: 'Bala', label: 'Bala' },
    { value: 'Balaji', label: 'Balaji' },
    { value: 'David Bright Livingstone', label: 'David Bright Livingstone' },
    { value: 'Gowri R', label: 'Gowri R' },
    { value: 'Jerin Pandian R', label: 'Jerin Pandian R' },
    { value: 'Kannan M', label: 'Kannan M' },
    { value: 'Kavitha E', label: 'Kavitha E' },
    { value: 'Kowsalya H', label: 'Kowsalya H' },
    { value: 'Manivel', label: 'Manivel' },
    { value: 'Manoj', label: 'Manoj' },
    { value: 'Heartbeat', label: 'Heartbeat' },
    { value: 'Question', label: 'Question' },
    { value: 'Announcement', label: 'Announcement' },
    { value: 'FYI', label: 'FYI' },
    { value: 'Pitch', label: 'Pitch' },
    { value: 'Personal mastery', label: 'Personal mastery' },
  ];

  const [selecteEverywhere, setSelectedEverywhere] = useState('');
  const handleChangesd = (s) => {
    setSelectedEverywhere(s.target.value);
  };

  const Everywhere = [
    { value: '', label: 'Everywhere' },
    { value: 'Webnexs HQ', label: 'Webnexs HQ' },
  ];

  const handleLogout = () => {
    // Perform logout actions here
    onLogout(); // Call the onLogout function passed as props
    navigate("/", { replace: false }); // Redirect to login page after logout
  };
  const [showModal, setShowModal] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const handleOrganizationClick = (org) => {
    setSelectedOrg(org);
    setShowModal(true);
  };
  const handleSwitchOrganization = async () => {
    if (!selectedOrg) return;

    try {
      const token = localStorage.getItem("token");
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/select-org`,
        { organizationId: selectedOrg.id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Store new token
      localStorage.setItem("token", data.token);
      localStorage.setItem("currentOrg", selectedOrg.id)
      // toast.success(`Switched to ${selectedOrg.name}`);

      // Close modal
      setShowModal(false);

      // Redirect to new org dashboard
      navigate("/loading", {
        state: { organizationId: selectedOrg.id, organizationName: selectedOrg.name, fromLogin: true }
      });

    } catch (error) {
      console.error("Switch Error:", error.response?.data?.message);
      toast.error("Failed to switch organization.");
    }
  };
  // console.log("ping user,", pingUsers)
  return (
    <div className="Header">
      <Navbar className='Navbar fixed d-flex justify-content-between pb-0 shadow-sm p-3 rounded' variant="dark">
        <Dropdown show={showorgDropdown} onToggle={(isOpen) => setShoworgDropdown(isOpen)}>
          <div className="logohead pl-2 d-flex align-items-center" onClick={() => setShoworgDropdown((prev) => !prev)} style={{ cursor: "pointer" }}>
            <img src='https://imagedelivery.net/r89jzjNfZziPHJz5JXGOCw/1dd59d6a-7b64-49d7-ea24-1366e2f48300/public' className='text-light mb-1 me-1 logo' width="32px" height='32px' alt='' />
            <Navbar.Brand to="#home" className="demo-theme-test fw-bold m-0">TeamNex</Navbar.Brand>
          </div>
          {/* <OrganizationDropdown /> */}
          <OrganizationDropdown organizations={userorganizations} isLoading={isLoading} onSelect={handleOrganizationClick} />
        </Dropdown>
        <Modal show={showModal} onHide={() => setShowModal(false)} className='position-fixed'>
          <Modal.Header closeButton className='secondary-class border-bottom-0'>
            <Modal.Title>Switch Organization</Modal.Title>
          </Modal.Header>
          <Modal.Body className='secondary-class'>
            <h6>
              Are you sure you want to switch to <strong>{selectedOrg?.name}</strong>?
            </h6>
          </Modal.Body>
          <Modal.Footer className='secondary-class border-top-0'>
            <button className="camp-cancel secondary-class px-4 py-2" onClick={() => setShowModal(false)}>
              Cancel
            </button>
            <button onClick={handleSwitchOrganization} className=' px-4 py-2 action-btn border-none'>
              Yes, Switch
            </button>
          </Modal.Footer>
        </Modal>
        <Navbar id="basic-navbar-nav" className='justify-content-center'>
          <Nav>
            <Link to={`/${organizationId}`} className="demo-theme-test PingsDw text-decoration-none mx-2 fw-bold"> <FontAwesomeIcon icon={faHome} /> Home</Link>
            {/* Hide for demo */}
            <Link to={`/${organizationId}/lineup`} className="demo-theme-test PingsDw text-decoration-none mx-2 text-center fw-bold"> <FontAwesomeIcon icon={faBars} className='me-1' />Line Up</Link>
            <PingsDropDown
              pingUsers={pingUsers}
              handleCreate={handleCreate}
              // handlePing={handlePing}
              formatOptionLabel={formatOptionLabel}
              organizationId={organizationId}
              userId={currentUserId}
              selectedUsers={selectedUsers}
              setSelectedUsers={setSelectedUsers}
              pingLoading={pingLoading}
            />
            {/* Hide for demo */}
            {/* <Dropdown
              className="PingsDw"
            >
              <DropdownToggle className="PingsDw p-0 demo-theme-test mx-2 fw-bold" >
                <div className='demo-theme-test Pingsfl'>
                  <FontAwesomeIcon icon={faComment} /> Hey!
                </div>
              </DropdownToggle>
              <DropdownMenu className="PingsDw Pingshw p-3">
                <div className="ms-3 p-2 d-flex justify-content-between">
                  <p className="heyNew mb-0">New for you</p>
                  <Link to="#" className="text-light text-decoration-none">Mark all read</Link>
                </div>
                <div className="ms-4 mb-2">
                  <p className="notification-item mb-1 t-color1">What was that on your plate today?</p>
                </div>


                <div className="notification-item d-flex align-items-start mb-2 t-color1">
                  <FontAwesomeIcon icon={faComment} className="me-2 t-color1" />
                  <div>
                    <p className=" mb-0 fw-bold">@mentioned you in:</p>
                    <p className=" mb-0">Re: To work on changing the Google login ID.</p>
                    <small className="text-muted">9:05am</small>
                  </div>
                </div>


              </DropdownMenu>
            </Dropdown> */}
            <HeyDropdown organizationId={organizationId} userId={currentUserId} />
            {/* <Link to={`${organizationId}/activity`} className="demo-theme-test PingsDw mx-1 fw-bold text-decoration-none mt-1"> <FontAwesomeIcon icon={faClock} />  Activity</Link> */}
            <Dropdown className=" StuffDw ">
              <DropdownToggle className=" StuffDw p-0 ">
                <div className="demo-theme-test PingsDw  Pingsfl mx-2 fw-bold"> <FontAwesomeIcon icon={faSmile} />My Stuff</div>
              </DropdownToggle>
              <DropdownMenu className='StuffDw myStuffhw p-3 overflow-auto'>
                <p className="fw-bold text-danger">Coming soon...</p>
                <Link to={`${organizationId}/my/assignments`} className="bg_my_Stuff t-color1 text-decoration-none">
                  <div className='p-2'> <button className=' me-2 mystuffBtn'>
                    <SiVerizon />
                  </button>My Assignments</div>
                </Link>
                <Link to={`${organizationId}/my/bookmarks`} className="bg_my_Stuff t-color1 text-decoration-none">
                  <div className='p-2'> <button className=' me-2 mystuffBtn'><IoBookmark /></button>My Bookmarks</div>
                </Link>
                <Link to={`${organizationId}/my/schedules`} className="bg_my_Stuff t-color1 text-decoration-none">
                  <div className='p-2'> <button className=' me-2 mystuffBtn'><AiFillSchedule /></button>My Schedule</div>
                </Link>
                <Link to={`${organizationId}/my/drafts`} className='bg_my_Stuff t-color1 text-decoration-none'>
                  <div className="p-2"> <button className=' me-2 mystuffBtn'><AiFillFileText /></button>My Drafts</div>
                </Link>
                <Link to="/activity" className='bg_my_Stuff t-color1 text-decoration-none'>
                  <div className="p-2"> <button className=' me-2 mystuffBtn'><GoClockFill /></button>My Recent Activity</div>
                </Link>
                <Link to={`${organizationId}/my/boosts`} className='bg_my_Stuff t-color1 text-decoration-none' >
                  <div className="p-2"> <button className=' me-2 mystuffBtn'><MdRocketLaunch /></button>My Boosts</div>
                </Link>
                <hr className="line" />
                <div>
                  <Link to="#" className='text-decoration-none'><p className='t-color1 fw-bold'>Recently visited</p></Link>
                  <Link to="#" className='text-decoration-none'><p className='t-color1 '>All the latest activity</p></Link>
                  <Link to="#" className='text-decoration-none'><p className='t-color1 '>My assignments</p></Link>
                  <Link to="#" className='text-decoration-none'><p className='t-color1 '>Scheduled projects</p></Link>
                  <Link to="#" className='text-decoration-none'><p className='t-color1 '>Adminland</p></Link>
                  <Link to="#" className='text-decoration-none'><p className='t-color1 '>webnexs HQ</p></Link>
                  <Link to="#" className='text-decoration-none'><p className='t-color1 '>Campfire - webnexs HQ</p></Link>
                </div>
                <div className='text-white d-flex justify-content-center'>Press<kbd>ctrl + J</kbd>for more recent history</div>
              </DropdownMenu>
            </Dropdown>
            <Dropdown className="PingsDw ">
              <DropdownToggle className="PingsDw p-0 ">
                <div className="demo-theme-test Pingsfl mx-2  fw-bold"><FontAwesomeIcon icon={faSearch} />Find</div>
              </DropdownToggle>
              <DropdownMenu className='PingsDw Pingshw find-exclude'>
                <p className="fw-bold text-danger">Coming soon...</p>
                <div className="mt-3 ms-3 me-3 ">
                  <input type="text" className='findText' />
                </div>
                <div className='p-3'>
                  <span className="text-start ms-3 secondary-class">
                    <select
                      className='findMessages secondary-class'
                      id="day-selector"
                      value={selecteEverything}
                      onChange={handleChanges}
                    >
                      {Everything.map((Everything) => (
                        <option key={Everything.value} value={Everything.value}>
                          {Everything.label}
                        </option>
                      ))}
                    </select>
                  </span>
                  <span className="text-center ms-3 secondary-class">
                    <select
                      className='findMessages secondary-class'
                      id="day-selector"
                      value={selectedAnyone}
                      onChange={handleChange}
                    >
                      {Anyone.map((Anyone) => (
                        <option key={Anyone.value} value={Anyone.value}>
                          {Anyone.label}
                        </option>
                      ))}
                    </select>
                  </span>
                  <span className="text-end ms-3">
                    <select
                      className='findMessages secondary-class'
                      id="day-selector"
                      value={selecteEverywhere}
                      onChange={handleChangesd}
                    >
                      {Everywhere.map((Everywhere) => (
                        <option key={Everywhere.value} value={Everywhere.value}>
                          {Everywhere.label}
                        </option>
                      ))}
                    </select>
                  </span>
                </div>
              </DropdownMenu>
            </Dropdown>
          </Nav>
        </Navbar>
        <Nav>
          <Dropdown className="ml-2 DDMenu">
            <DropdownToggle className="Profile-btn rounded-circle bg-success p-2 not-exclude">
              <DropdownToggle className="Profile-btn rounded-circle bg-success p-2 not-exclude">
                {userData?.user?.profilePhoto ? (
                  <img
                    src={`${process.env.REACT_APP_API_URL}${userData.user.profilePhoto}`}
                    alt="Profile"
                    className="Profile-icon rounded-circle"
                    style={{ width: "35px", height: "35px", objectFit: "cover" }}
                  />
                ) : (
                  <b className='d-flex justify-content-center Profile-icon'>
                    {userData?.user?.username?.charAt(0).toUpperCase()}
                  </b>
                )}
              </DropdownToggle>

            </DropdownToggle>
            <DropdownMenu className='dropdown-menu-end'>
              <div className="p-3 dropdown-box">
                {/* <div className='head t-color1' >
                  <h6 className="mb-3 fw-bold "><FontAwesomeIcon icon={faBell} /> Notifications are on <hr style={{ display: 'inline-block', width: '100px', verticalAlign: 'middle', margin: '0 10px' }} /> </h6>
                  <p className=''>You're set to receive email and browser
                    <br />notifications for everything all the time.</p>
                  <button className="bttn  m-2">Turn on Focus Mode</button>
                  <Link to={`${organizationId}/my/notifications/settings/edit`} className="d-block t-color1 mb-2 ">Change your notification settings</Link>
                  <Link to="#" className="d-block t-color1 mb-4 ">Turn notifications off</Link>
                </div> */}
                <div className='bottom'>
                  <Link to="#" className="d-block mb-3 t-color1 text-decoration-none fw-bold">Personal Settings <hr style={{ display: 'inline-block', width: '100px', verticalAlign: 'middle', margin: '0 10px' }} /></Link>
                  <Link to={`${organizationId}/my/profile`} className="d-block mb-2 t-color1 text-decoration-none">Profile, password, 2FA</Link>
                  {/* <Link to={`${organizationId}/my/devices`} className="d-block mb-2 t-color1 text-decoration-none">My devices (Get our free mobile apps)</Link> */}
                  <Link to={`${organizationId}/my/preference`} className="d-block mb-2 t-color1 text-decoration-none">Preferences</Link>
                  {/* <Link to={`${organizationId}/my/out_of_office`} className="d-block mb-2 t-color1 text-decoration-none">Out of office</Link> */}
                  <Link to="#" onClick={handleLogout} className="d-block mb-2 t-color1 text-decoration-none">Log out</Link>
                </div>
              </div>
            </DropdownMenu>
          </Dropdown>
        </Nav>
      </Navbar>
    </div >
  )
}

export default HomeNav;