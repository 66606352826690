// src/routes/index.js
import { lazy } from 'react';
import ViewSchedule from '../components/ProjectPage/Schedule/ViewSchedule';
import TaskList from '../components/ProjectPage/TaskList';
import LoginLoader from '../Auth/LoginLoader';


// Authentication
export const authRoutes = {
    Login: lazy(() => import('../components/Authentication/Login')),
    Signup: lazy(() => import('../components/Authentication/Signup')),
    ForgotPass: lazy(() => import('../components/Authentication/ForgotPass')),
    ResetPassword: lazy(() => import('../components/Authentication/ResetPassword')),
    VerifyMail: lazy(() => import('../components/Authentication/VerifyMail')),
    NotVerified: lazy(() => import('../components/Authentication/NotVerified')),
    PauseOrganization: lazy(() => import('../components/Authentication/PauseOrganization')),
    SelectOrganization: lazy(() => import('../Auth/SelectOrganization')),
    LoginLoader: lazy(() => import('../Auth/LoginLoader')),
};

// Admin Routes
export const adminRoutes = {
    Adminlogin: lazy(() => import('../SuperAdmin/Adminlogin')),
    AdminDashboard: lazy(() => import('../SuperAdmin/AdminDashboard')),
    Administrators: lazy(() => import('../components/HomePageComponents/Adminland/Administrators')),
    CreateRole: lazy(() => import('../components/HomePageComponents/Adminland/CreateRole')),

};

// Home Routes
export const homeRoutes = {
    Home: lazy(() => import('../components/Home')),
    QuickAccess: lazy(() => import('../components/HomePageComponents/QuickAccess')),
    Createproject: lazy(() => import('../components/HomePageComponents/Createproject'))
};

// Project Routes
export const projectRoutes = {
    ProjectCard: lazy(() => import('../components/ProjectPage/ProjectCard')),
    Campfire: lazy(() => import('../components/ProjectPage/Campfire')),
    MessageBoard: lazy(() => import('../components/ProjectPage/MessageBoard/MessageBoard')),
    MessageShow: lazy(() => import('../components/ProjectPage/MessageBoard/MessageShow')),
    NewMessage: lazy(() => import('../components/ProjectPage/MessageBoard/NewMessage')),
    SubscriberList: lazy(() => import('../components/ProjectPage/MessageBoard/SubscriberList')),
    ToDos: lazy(() => import('../components/ProjectPage/To-Dos')),
    Task: lazy(() => import('../components/ProjectPage/task')),
    DocsFiles: lazy(() => import('../components/ProjectPage/Docs&Files')),
    ViewDocument: lazy(() => import('../components/ProjectPage/Docs%Files/ViewDocument')),
    ViewFiles: lazy(() => import('../components/ProjectPage/Docs%Files/ViewFiles')),
    ReplaceFile: lazy(() => import('../components/ProjectPage/Docs%Files/ReplaceFile')),
    AllVersions: lazy(() => import('../components/ProjectPage/Docs%Files/AllVersions')),
    NewDocument: lazy(() => import('../components/ProjectPage/Docs%Files/NewDocument')),
    ProjectSchedule: lazy(() => import('../components/ProjectPage/ProjectSchedule')),
    Autocheckin: lazy(() => import('../components/ProjectPage/Autocheckin')),
    EmailForward: lazy(() => import('../components/ProjectPage/EmailForward')),
    CardTable: lazy(() => import('../components/ProjectPage/CardTable')),
    AutocheckinNewQuestion: lazy(() => import('../components/ProjectPage/Docs%Files/NewDocument')),
    SetUpToolThisProject: lazy(() => import('../components/ProjectPage/SetUpToolThisProject')),
    Schedule: lazy(() => import('../components/ProjectPage/Schedule/Schedule')),
    ViewSchedule: lazy(() => import('../components/ProjectPage/Schedule/ViewSchedule')),
    TaskList: lazy(() => import('../components/ProjectPage/TaskList')),
};



// MyStuff Routes
export const myStuffRoutes = {
    Assignment: lazy(() => import('../components/MyStuff/Assignment')),
    Bookmark: lazy(() => import('../components/MyStuff/Bookmark')),
    Schedule: lazy(() => import('../components/MyStuff/MySchedule')),
    Activity: lazy(() => import('../components/MyStuff/Recent_Activity/Activity')),
    Boosts: lazy(() => import('../components/MyStuff/Boosts')),
    Drafts: lazy(() => import('../components/MyStuff/Drafts')),
    PrivateChatinPing: lazy(() => import('../components/HomePageComponents/Ping/PrivateChatinPing'))
};


//Activity
export const activityRoutes = {
    Latestactivity: lazy(() => import('../components/MyStuff/Recent_Activity/Latestactivity')),
    SomeonesActivity: lazy(() => import('../components/MyStuff/Recent_Activity/SomeonesActivity')),
    Missioncontrol: lazy(() => import('../components/MyStuff/Recent_Activity/Missioncontrol')),
    Todos: lazy(() => import('../components/MyStuff/Recent_Activity/Todos')),
    UnassignedWork: lazy(() => import('../components/MyStuff/Recent_Activity/UnassignedWork')),
    UpcomingDates: lazy(() => import('../components/MyStuff/Recent_Activity/UpcomingDates')),
    SomeonesAssignment: lazy(() => import('../components/MyStuff/Recent_Activity/SomeonesAssignment')),
    TimeSheet: lazy(() => import('../components/MyStuff/Recent_Activity/TimeSheet')),
    Overdue: lazy(() => import('../components/MyStuff/Recent_Activity/Overdue')),

}

//ProfileRoutes
export const profileRoutes = {
    Preferences: lazy(() => import('../components/Profile-Dropdown/Preferences')),
    OutOfOffice: lazy(() => import('../components/Profile-Dropdown/OutOfOffice')),
    PersonalCard: lazy(() => import('../components/Profile-Dropdown/PersonalCard')),
    MyDevices: lazy(() => import('../components/Profile-Dropdown/MyDevices')),
    NotificationSetting: lazy(() => import('../components/Profile-Dropdown/NotificationSetting')),

};

//invite routes
export const inviteRoutes = {
    InviteSuccess: lazy(() => import('../components/HomePageComponents/InviteUser/InviteSuccess')),
    InviteExist: lazy(() => import('../components/HomePageComponents/InviteUser/InviteExist')),
    InviteExistLogin: lazy(() => import('../components/HomePageComponents/InviteUser/InviteExistLogin')),
    InvitationHandler: lazy(() => import('../components/HomePageComponents/InviteUser/InviteHandler')),
    InviteNewuser: lazy(() => import('../components/HomePageComponents/InviteUser/InviteNewuser')),
    InvitePeople: lazy(() => import('../components/HomePageComponents/InvitePeople')),
    CoworkersAccount: lazy(() => import('../components/HomePageComponents/CoworkersAccount')),
    InviteLink: lazy(() => import('../components/HomePageComponents/InviteLink')),

}

export const roleRoutes = {
    CreateRole: lazy(() => import('../components/HomePageComponents/Adminland/CreateRole')),
    SetPermission: lazy(() => import('../components/HomePageComponents/Adminland/SetPermission')),
    ViewPermission: lazy(() => import('../components/HomePageComponents/Adminland/ViewPermission')),
    EditPermission: lazy(() => import('../components/HomePageComponents/Adminland/EditPermission')),

}


//Adminland Routes

export const adminlandRoutes = {
    Adminland: lazy(() => import('../components/HomePageComponents/Adminland')),
    Adminland1: lazy(() => import('../components/HomePageComponents/Adminland1')),
    Administrators: lazy(() => import('../components/HomePageComponents/Adminland/Administrators')),
    Changecontent: lazy(() => import('../components/HomePageComponents/Adminland/Changecontent')),
    RenameProjectTools: lazy(() => import('../components/HomePageComponents/Adminland/RenameProjectTools')),
    ChangeMessageCategories: lazy(() => import('../components/HomePageComponents/Adminland/ChangeMessageCategories')),
    ChangePings: lazy(() => import('../components/HomePageComponents/Adminland/ChangePings')),
    EditProjectSettings: lazy(() => import('../components/HomePageComponents/Adminland/EditProjectSettings')),
    PlanSelection: lazy(() => import('../components/HomePageComponents/Adminland/PlanSelection')),
    ManagePublicItems: lazy(() => import('../components/HomePageComponents/Adminland/ManagePublicItems')),
    CancelAccount: lazy(() => import('../components/HomePageComponents/Adminland/CancelAccount')),
    MergePeople: lazy(() => import('../components/HomePageComponents/Adminland/MergePeople')),
    MoveProject: lazy(() => import('../components/HomePageComponents/Adminland/MoveProject')),
    ManageGroups: lazy(() => import('../components/HomePageComponents/Adminland/ManageGroups')),
    ManageCompanies: lazy(() => import('../components/HomePageComponents/Adminland/ManageCompanies')),
    ArchiveAndDeleteProject: lazy(() => import('../components/HomePageComponents/Adminland/ArchiveAndDeleteProject')),
    EditProject: lazy(() => import('../components/HomePageComponents/Adminland/EditProject')),
    PublicLink: lazy(() => import('../components/HomePageComponents/Adminland/PublicLink')),
    TwoFactorAuthentication: lazy(() => import('../components/HomePageComponents/Adminland/TwoFactorAuthentication')),
    PingAndChatHistory: lazy(() => import('../components/HomePageComponents/Adminland/PingAndChatHistory')),
    AddRemoveAcc: lazy(() => import('../components/HomePageComponents/Adminland/AddRemoveAcc')),
    AccountRename: lazy(() => import('../components/HomePageComponents/Adminland/AccountRename')),
    Trash: lazy(() => import('../components/HomePageComponents/Adminland/Trash')),
    Reassignment: lazy(() => import('../components/HomePageComponents/Adminland/Reassignment')),
    ProjectAccess: lazy(() => import('../components/HomePageComponents/Adminland/ProjectAccess')),
    ManageStorage: lazy(() => import('../components/HomePageComponents/Adminland/ManageStorage')),
    ExportData: lazy(() => import('../components/HomePageComponents/Adminland/ExportData')),

}

export const otherRoutes = {
    ProjectSettings: lazy(() => import('../components/ProjectPage/ProjectSettings')),
    JustFollow: lazy(() => import('../components/ProjectPage/JustFollow')),
    AddPeopleToProject: lazy(() => import('../components/ProjectPage/AddPeopleToProject')),
    SetUpPeople: lazy(() => import('../components/ProjectPage/SetUpPeople')),
    ToDoListTemplate: lazy(() => import('../components/HomePageComponents/ViewTemp/ToDo_List_Template')),
    ProjectTemplate: lazy(() => import('../components/HomePageComponents/ViewTemp/ProjectTemplate')),
    ViewTemplate: lazy(() => import('../components/HomePageComponents/ViewTemp/ViewTemplate')),
    ViewAllList: lazy(() => import('../components/HomePageComponents/ViewAllList')),
    NotfoundPage: lazy(() => import('../components/NotfoundPage')),
    AddEmployees: lazy(() => import('../components/AddEmployees')),
}