import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Dropdown, DropdownToggle, DropdownMenu } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import { Rings } from 'react-loader-spinner';
import { formatDistanceToNow, isToday, format } from "date-fns";
const NotificationDropdown = ({ organizationId, userId }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropdown = () => setDropdownOpen(prev => !prev);
    const closeDropdown = () => setDropdownOpen(false);

    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/api/organization/${organizationId}/user/${userId}/get-notifications`
                );

                const groupedNotifications = response.data.reduce((acc, notification) => {
                    // const key = `${notification.module}-${notification.projectId.name || 'General'}`;
                    const key = `${notification.module}-${notification.projectId?._id || 'General'}-${notification.moduleId || ''}`;

                    if (!acc[key]) {
                        acc[key] = { ...notification, count: 1 };
                    } else {
                        acc[key].count += 1;
                    }
                    return acc;
                }, {});

                setNotifications(Object.values(groupedNotifications));
            } catch (error) {
                console.error('Error fetching notifications:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchNotifications();
    }, [organizationId, userId]);

    // console.log("Notifications", notifications);
    const markAsRead = async (notification) => {
        try {
            const markGroup = notification.count > 1;
            await axios.post(
                `${process.env.REACT_APP_API_URL}/api/organization/${organizationId}/notifications/mark-read`,
                {
                    userId,
                    notificationId: notification._id,
                    module: notification.module,
                    projectId: notification.projectId?._id || null, // Ensure projectId is sent correctly
                    markGroup
                }
            );

            // Update the UI instantly
            // setNotifications(prevNotifications =>
            //     prevNotifications.map(n =>
            //         (markGroup && n.module === notification.module && n.projectId?._id === notification.projectId?._id) ||
            //             n._id === notification._id
            //             ? { ...n, readBy: [...n.readBy, userId] }
            //             : n
            //     )
            // );
            setNotifications(prevNotifications =>
                prevNotifications.map(n =>
                    (markGroup && n.module === notification.module && n.projectId?._id === notification.projectId?._id) ||
                        n._id === notification._id
                        ? { ...n, readBy: [...n.readBy, userId] }
                        : n
                )
            );

            closeDropdown();

            // Close dropdown properly
            setDropdownOpen(false);

            let path = "/";
            switch (notification.module) {
                case "schedule":
                    path = `${organizationId}/project/${notification.projectId?._id || "general"}`;
                    break;
                case "docs":
                    path = `${organizationId}/project/${notification.projectId?._id || "general"}`;
                    break;
                case "Messageboard":
                    path = `${organizationId}/project/${notification.projectId?._id || "general"}/messageBoard`;
                    break;
                case "MessageComment":
                    path = `${organizationId}/project/${notification.projectId?._id || "general"}/messageBoard/message/${notification.moduleId}`;
                    break;
                case "Campfire":
                    path = `${organizationId}/project/${notification.projectId?._id || "general"}/chat`;
                    break;
                case "New Task":
                    path = `${organizationId}/project/${notification.projectId?._id || "general"}/taskList/${notification.moduleId}`;
                    break;
                case "CheckIns":
                    path = `${organizationId}/project/${notification.projectId?._id || "general"}/autocheckins/${notification.moduleId}/answer`;
                    break;
                case "Auto-Check-ins":
                    path = `${organizationId}/project/${notification.projectId?._id || "general"}/autocheckins/${notification.moduleId}`;
                    break;
                default:
                    path = `/notification/${notification._id}`;
            }

            // Navigate to the notification page
            navigate(path);
        } catch (error) {
            console.error('Error marking notification as read:', error);
        }
    };

    const newNotifications = notifications.filter(notification => !notification.readBy.includes(userId));
    const previousNotifications = notifications.filter(notification => notification.readBy.includes(userId));
    const getInitials = (name) => {
        if (!name) return ''; // Handle cases where name might be undefined
        const nameArray = name.split(' ');
        return nameArray.map(n => n.charAt(0)).join('').toUpperCase();
    };
    const getColorFromId = (id) => {
        const colors = [
            '#1abc9c',
            '#e74c3c',
            '#3498db',
            '#9b59b6',
            '#f39c12',
            '#2ecc71',
            '#e67e22',
        ];

        if (!id) return colors[0];

        const hash = id.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);
        return colors[hash % colors.length];
    };
    return (
        <Dropdown className="PingsDw" direction="down" show={dropdownOpen} onToggle={(isOpen) => setDropdownOpen(isOpen)} >
            <DropdownToggle className="PingsDw p-0 demo-theme-test mx-2 fw-bold">
                <div className='demo-theme-test Pingsfl'>
                    <FontAwesomeIcon icon={faComment} /> Hey!
                    {newNotifications.length > 0 && (
                        <div
                            className="position-absolute translate-middle rounded-circle bg-danger"
                            style={{ left: '23px', top: '10px', padding: '4px' }}
                        ></div>
                    )}
                </div>
            </DropdownToggle>
            <DropdownMenu className="PingsDw Pingshw p-3 hey-container" style={{ transform: 'translateX(-50%)', maxHeight: '400px', overflowY: 'auto' }}>
                <div className="ms-3 p-2 d-flex justify-content-between">
                    {newNotifications.length > 0 && <p className="heyNew mb-0">New for you</p>}
                </div>
                {loading ? (
                    <div className="d-flex justify-content-center">
                        <Rings visible={true} height="80" width="80" color="var(--theme-color)" ariaLabel="rings-loading" />
                    </div>
                ) : newNotifications.length === 0 ? (
                    <div className="text-center p-3 t-color1 pb-5 boder-bottom">Nothing new for you...</div>
                ) : (
                    newNotifications.map(notification => (
                        <div

                            key={notification._id}
                            onClick={() => markAsRead(notification)}
                            className="notification-item d-block text-decoration-none p-2 mb-2  rounded t-color1"
                            style={{ position: 'relative' }}
                        >
                            <div className="d-flex align-items-start">
                                {/* {!notification.senderId &&
                                    <FontAwesomeIcon icon={faComment} className="me-2 t-color1 mt-1" />
                                } */}
                                {notification.senderId ? (
                                    notification.senderId.profilePhoto ? (
                                        <img
                                            src={`${process.env.REACT_APP_API_URL}${notification.senderId.profilePhoto}`}
                                            alt="Profile"
                                            style={{
                                                width: '50px',
                                                height: '50px',
                                                borderRadius: '50%',
                                            }}
                                            className="d-flex justify-content-center align-items-center me-3"
                                        />
                                    ) : (
                                        <span
                                            style={{
                                                width: '50px',
                                                height: '50px',
                                                borderRadius: '50%',
                                                backgroundColor: getColorFromId(notification.senderId._id),
                                                fontSize: '16px',
                                            }}
                                            className="t-color1 d-flex justify-content-center align-items-center me-3"
                                        >
                                            {getInitials(notification.senderId.username)}
                                        </span>
                                    )
                                ) : null}

                                {!notification.senderId && <div>
                                    {/* <p className="mb-0 fw-bold">{notification.module} - {notification.projectId.name || 'General'}</p> */}
                                    <div className='d-flex align-items-center'>
                                        <p className="mb-0 me-2" dangerouslySetInnerHTML={{ __html: notification.message }}></p>
                                        <small className="text-secondary">{new Date(notification.createdAt).toLocaleTimeString()}</small>
                                    </div>
                                    <div> <small className="text-secondary">{notification.senderId?.username}</small></div>
                                </div>}
                                {notification?.senderId &&
                                    <div>
                                        <p className="mb-0 fw-bold">
                                            {notification.type === 'assign' && notification?.senderId ?
                                                `${notification.senderId.username} assigned you a task in ${notification.projectId.name}` :
                                                <div>
                                                    {`${notification.message}`}

                                                </div>
                                            }
                                        </p>
                                        <small className='text-secondary me-2'>{notification.senderId.username}</small>

                                        <small className="text-secondary">
                                            {isToday(new Date(notification.createdAt))
                                                ? formatDistanceToNow(new Date(notification.createdAt), { addSuffix: true })
                                                : format(new Date(notification.createdAt), "MMM d")}
                                        </small>
                                    </div>}

                            </div>
                            {notification.count > 1 && (
                                <span className="badge bg-primary position-absolute end-0 me-2" style={{ top: '7px' }}>{notification.count}</span>
                            )}
                        </div>
                    ))
                )}

                <div className="p-2 d-flex justify-content-between mt-3">
                    <p className="t-color1 fw-bold mb-0">Previous Messages</p>
                </div>
                {previousNotifications.length === 0 ? (
                    <div className="text-center p-3 t-color1">No previous messages</div>
                ) : (
                    previousNotifications.map(notification => (
                        <div
                            key={notification._id}
                            onClick={() => markAsRead(notification)}
                            className="notification-item d-block text-decoration-none p-2 mb-2  rounded t-color1"
                            style={{ position: 'relative' }}
                        >
                            <div className="d-flex align-items-start">
                                {/* {!notification.senderId &&
                                    <FontAwesomeIcon icon={faComment} className="me-2 t-color1 mt-1" />
                                } */}
                                {notification.senderId ? (
                                    notification.senderId.profilePhoto ? (
                                        <img
                                            src={`${process.env.REACT_APP_API_URL}${notification.senderId.profilePhoto}`}
                                            alt="Profile"
                                            style={{
                                                width: '50px',
                                                height: '50px',
                                                borderRadius: '50%',
                                            }}
                                            className="d-flex justify-content-center align-items-center me-3"
                                        />
                                    ) : (
                                        <span
                                            style={{
                                                width: '50px',
                                                height: '50px',
                                                borderRadius: '50%',
                                                backgroundColor: getColorFromId(notification.senderId._id),
                                                fontSize: '16px',
                                            }}
                                            className="t-color1 d-flex justify-content-center align-items-center me-3"
                                        >
                                            {getInitials(notification.senderId.username)}
                                        </span>
                                    )
                                ) : null}

                                <div>
                                    {/* <p className="mb-0 fw-bold">
                                        {notification.type === 'assign' && notification?.senderId ?
                                            `${notification.senderId.username} assigned you a task in ${notification.projectId.name}` :
                                            `${notification.module} - ${notification.projectId.name || 'General'}`}
                                    </p> */}
                                    <div className='d-flex align-items-center'>
                                        <p className="mb-0 me-2" dangerouslySetInnerHTML={{ __html: notification.message }}></p>
                                        <small className="text-secondary">
                                            {isToday(new Date(notification.createdAt))
                                                ? formatDistanceToNow(new Date(notification.createdAt), { addSuffix: true })
                                                : format(new Date(notification.createdAt), "MMM d")}
                                        </small>
                                    </div>
                                    <div> <small className="text-secondary">{notification.senderId?.username}</small></div>
                                </div>
                            </div>
                            {/* {notification.count > 1 && (
                                <span className="badge bg-primary position-absolute end-0 me-2" style={{ top: '7px' }}>{notification.count}</span>
                            )} */}
                        </div>
                    ))
                )}
            </DropdownMenu>
        </Dropdown>
    );
};

export default NotificationDropdown;


// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { Dropdown, DropdownToggle, DropdownMenu } from 'react-bootstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faComment } from '@fortawesome/free-solid-svg-icons';
// import { Link } from 'react-router-dom';
// import { Rings } from 'react-loader-spinner';
// import { formatDistanceToNow, isToday, format } from "date-fns";

// const NotificationDropdown = ({ organizationId, userId }) => {
//     const [dropdownOpen, setDropdownOpen] = useState(false);
//     const [notifications, setNotifications] = useState([]);
//     const [loading, setLoading] = useState(true);

//     const toggleDropdown = () => setDropdownOpen(prev => !prev);

//     useEffect(() => {
//         const fetchNotifications = async () => {
//             try {
//                 const response = await axios.get(
//                     `${process.env.REACT_APP_API_URL}/api/organization/${organizationId}/user/${userId}/get-notifications`
//                 );

//                 const groupedNotifications = response.data.reduce((acc, notification) => {
//                     const key = `${notification.module}-${notification.projectId?._id || 'General'}-${notification.moduleId || ''}`;
//                     if (!acc[key]) {
//                         acc[key] = { ...notification, count: 1 };
//                     } else {
//                         acc[key].count += 1;
//                     }
//                     return acc;
//                 }, {});

//                 setNotifications(Object.values(groupedNotifications));
//             } catch (error) {
//                 console.error('Error fetching notifications:', error);
//             } finally {
//                 setLoading(false);
//             }
//         };

//         fetchNotifications();
//     }, [organizationId, userId]);

//     const markAsRead = async (notification) => {
//         try {
//             const markGroup = notification.count > 1;
//             await axios.post(
//                 `${process.env.REACT_APP_API_URL}/api/organization/${organizationId}/notifications/mark-read`,
//                 {
//                     userId,
//                     notificationId: notification._id,
//                     module: notification.module,
//                     projectId: notification.projectId?._id || null,
//                     markGroup
//                 }
//             );

//             setNotifications(prevNotifications =>
//                 prevNotifications.map(n =>
//                     (markGroup && n.module === notification.module && n.projectId?._id === notification.projectId?._id) ||
//                         n._id === notification._id
//                         ? { ...n, readBy: [...n.readBy, userId] }
//                         : n
//                 )
//             );
//         } catch (error) {
//             console.error('Error marking notification as read:', error);
//         }
//     };

//     const getNotificationPath = (notification) => {
//         switch (notification.module) {
//             case "schedule":
//             case "docs":
//                 return `/${organizationId}/project/${notification.projectId?._id || "general"}`;
//             case "Ping":
//                 return `/${organizationId}/chat/${notification.moduleId || "general"}`;
//             case "Messageboard":
//                 return `/${organizationId}/project/${notification.projectId?._id || "general"}/messageBoard`;
//             case "MessageComment":
//                 return `/${organizationId}/project/${notification.projectId?._id || "general"}/messageBoard/message/${notification.moduleId}`;
//             case "Campfire":
//                 return `/${organizationId}/project/${notification.projectId?._id || "general"}/chat`;
//             case "New Task":
//                 return `/${organizationId}/project/${notification.projectId?._id || "general"}/taskList/${notification.moduleId}`;
//             case "CheckIns":
//                 return `/${organizationId}/project/${notification.projectId?._id || "general"}/autocheckins/${notification.moduleId}`;
//             default:
//                 return `/notification/${notification._id}`;
//         }
//     };

//     const newNotifications = notifications.filter(notification => !notification.readBy.includes(userId));
//     const previousNotifications = notifications.filter(notification => notification.readBy.includes(userId));

//     return (
//         <Dropdown className="PingsDw" direction="down" show={dropdownOpen} onToggle={toggleDropdown}>
//             <DropdownToggle className="PingsDw p-0 demo-theme-test mx-2 fw-bold">
//                 <div className='demo-theme-test Pingsfl'>
//                     <FontAwesomeIcon icon={faComment} /> Hey!
//                     {newNotifications.length > 0 && (
//                         <div className="position-absolute translate-middle rounded-circle bg-danger"
//                             style={{ left: '23px', top: '10px', padding: '4px' }}>
//                         </div>
//                     )}
//                 </div>
//             </DropdownToggle>
//             <DropdownMenu className="PingsDw Pingshw p-3 hey-container" style={{ transform: 'translateX(-50%)', maxHeight: '400px', overflowY: 'auto' }}>
//                 <div className="ms-3 p-2 d-flex justify-content-between">
//                     {newNotifications.length > 0 && <p className="heyNew mb-0">New for you</p>}
//                 </div>

//                 {loading ? (
//                     <div className="d-flex justify-content-center">
//                         <Rings visible={true} height="80" width="80" color="var(--theme-color)" ariaLabel="rings-loading" />
//                     </div>
//                 ) : newNotifications.length === 0 ? (
//                     <div className="text-center p-3 t-color1">Nothing new for you...</div>
//                 ) : (
//                     newNotifications.map(notification => (
//                         <Link
//                             to={getNotificationPath(notification)}
//                             key={notification._id}
//                             className="notification-item d-block text-decoration-none p-2 mb-2 border rounded t-color1"
//                             style={{ position: 'relative' }}
//                             onClick={() => markAsRead(notification)}
//                         >
//                             <div className="d-flex align-items-start">
//                                 <FontAwesomeIcon icon={faComment} className="me-2 t-color1 mt-1" />
//                                 <div>
//                                     <p className="mb-0 me-2" dangerouslySetInnerHTML={{ __html: notification.message }}></p>
//                                     <small className="text-secondary">
//                                         {isToday(new Date(notification.createdAt))
//                                             ? formatDistanceToNow(new Date(notification.createdAt), { addSuffix: true })
//                                             : format(new Date(notification.createdAt), "MMM d")}
//                                     </small>
//                                 </div>
//                             </div>
//                             {notification.count > 1 && (
//                                 <span className="badge bg-primary position-absolute end-0 me-2" style={{ top: '7px' }}>{notification.count}</span>
//                             )}
//                         </Link>
//                     ))
//                 )}

//                 <div className="p-2 d-flex justify-content-between mt-3">
//                     <p className="t-color1 fw-bold mb-0">Previous Messages</p>
//                 </div>

//                 {previousNotifications.length === 0 ? (
//                     <div className="text-center p-3 t-color1">No previous messages</div>
//                 ) : (
//                     previousNotifications.map(notification => (
//                         <Link
//                             to={getNotificationPath(notification)}
//                             key={notification._id}
//                             className="notification-item d-block text-decoration-none p-2 mb-2 border rounded t-color1"
//                             style={{ position: 'relative' }}
//                             onClick={() => markAsRead(notification)}
//                         >
//                             <div className="d-flex align-items-start">
//                                 <FontAwesomeIcon icon={faComment} className="me-2 t-color1 mt-1" />
//                                 <div>
//                                     <p className="mb-0 me-2" dangerouslySetInnerHTML={{ __html: notification.message }}></p>
//                                     <small className="text-secondary">
//                                         {isToday(new Date(notification.createdAt))
//                                             ? formatDistanceToNow(new Date(notification.createdAt), { addSuffix: true })
//                                             : format(new Date(notification.createdAt), "MMM d")}
//                                     </small>
//                                 </div>
//                             </div>
//                         </Link>
//                     ))
//                 )}
//             </DropdownMenu>
//         </Dropdown>
//     );
// };

// export default NotificationDropdown;
