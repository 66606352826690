import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { ProgressBar } from 'react-loader-spinner';
import { useLocation, useNavigate } from "react-router-dom";
const LoginLoader = () => {
    const [loaderColor, setLoaderColor] = useState("green");
    const location = useLocation();
    const navigate = useNavigate();
    const { organizationId, userData, fromLogin, organizationName } = location.state || {};
    useEffect(() => {
        // Prevent manual access to /loading
        if (!fromLogin || !organizationId) {
            navigate("/"); // Redirect to home or login
            return;
        }

        const timer = setTimeout(() => {
            navigate(`/${organizationId}`, { state: { userData } });
        }, 2000); // Simulate data fetching

        return () => clearTimeout(timer);
    }, [organizationId, navigate, userData, fromLogin]);

    useEffect(() => {

        const themeColor = getComputedStyle(document.documentElement).getPropertyValue("--theme-color").trim();


        setLoaderColor(themeColor || "green");
    }, []);

    return (
        // <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", flexDirection: "column" }}>
        //     {/* <ProgressBar
        //         visible={true}
        //         height="80"
        //         width="80"
        //         color={loaderColor}
        //         ariaLabel="rings-loading"
        //     />
        //     <h3>Logging you in...</h3> */}

        // </div>
        <div className="d-flex justify-content-center align-items-center mt-5 password">
            <div className="Loginpage text-center" style={{ maxWidth: '448px', width: '100%' }}>
                <img src='https://imagedelivery.net/r89jzjNfZziPHJz5JXGOCw/1dd59d6a-7b64-49d7-ea24-1366e2f48300/public' alt="Logo" className="d-block m-auto" style={{ width: '100px' }} />
                {/* {/* <h5 className="card-title text-center text-black mb-3">Try TeamNex for free</h5> */}
                <h3 className="text-center card-title text-black text-m">Teamnex</h3>
                <div className="card p-4 mt-4 b-shad">
                    <div className="card-body">
                        <h3 className=" mt-0 mb-2 text-black text-center fw-bold">
                            {/* Logging you in... */}
                            {organizationName ? `Switching to ${organizationName}...` : "Logging you in..."}
                        </h3>
                        <p className="mt-0 mb-2 text-black"></p>
                        <ProgressBar
                            visible={true}
                            height="80"
                            width="80"
                            color={loaderColor}
                            ariaLabel="rings-loading"
                        />
                    </div>
                </div>

            </div>
        </div>
    );
};


export default LoginLoader
