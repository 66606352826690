import axios from 'axios';
import { createContext, useState, useEffect, useContext } from 'react'
const { useNavigate } = require("react-router-dom");

const AuthContext = createContext();

export const AuthProvider = ({ children, signout, rolePermissions }) => {

    return (
        <AuthContext.Provider value={{ signout, rolePermissions }}>
            {children}
        </AuthContext.Provider>
    )
}
export const useAuth = () => useContext(AuthContext);