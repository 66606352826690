import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faTh, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { MdArchive, MdDelete, MdModeEdit, MdNavigateNext } from 'react-icons/md';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { jwtDecode } from 'jwt-decode';
import socket from '../../../socket/socket';
import { FaDownload, FaFrownOpen, FaRegBookmark, FaRegTrashAlt } from 'react-icons/fa';
import { IoIosLink, IoMdPeople, IoMdPhotos } from 'react-icons/io';
import { TbHandClick, TbMessageOff } from 'react-icons/tb';
import toast from 'react-hot-toast';
import { IoCopyOutline } from 'react-icons/io5';
// import { LuMessageSquareOff } from "react-icons/lu";
const ViewSchedule = () => {
    const { scheduleId, id: projectId } = useParams();
    const [isOpen, setIsOpen] = useState(false);
    const opensideNav = () => setIsOpen(true);
    const closesideNav = () => setIsOpen(false);
    const location = useLocation();
    const navigate = useNavigate()
    const [item, setItem] = useState(location.state?.event || null);
    const [breadcrumbs, setBreadcrumbs] = useState(location.state?.breadcrumbs || []);
    const [projectName, setProjectName] = useState(location.state?.projectname || "Project");
    // console.log("schedule location data", item)
    const entityType = location.pathname.includes("/docsfiles/file")
        ? "File"
        : location.pathname.includes("/docsfiles/document")
            ? "Document"
            : null
    // console.log("Entity type", entityType)
    const [document, setDocument] = useState(location.state?.document || null);

    // console.log("Document data", document)
    const [data, setData] = useState(null)
    const [activeCommentId, setActiveCommentId] = useState(null);
    const [editCommentId, setEditCommentId] = useState(null);
    const openNav = (commentId) => {
        setActiveCommentId(commentId);
        setEditCommentId(null)
    };

    const closeNav = () => {
        setActiveCommentId(null);
    };


    // console.log(data)
    useEffect(() => {
        const savedToken = localStorage.getItem('token');
        if (savedToken) {
            const decodedToken = jwtDecode(savedToken);
            setData(decodedToken)
            // Check if the token is expired      
        } else {
            console.log('No valid userData found in localStorage');
        }
    }, []);
    const userName = data?.username
    const senderId = data?._id
    console.log("authot : ", senderId)
    const [content, setContent] = useState('');


    const [messages, setMessages] = useState([]);

    const [comments, setComments] = useState([])
    const [commentContent, setCommentContent] = useState({});
    const [message, setMessage] = useState(null);
    const textareaRef = useRef(null);
    const [subscribers, setSubscribers] = useState([
        { name: 'User1', id: 1, avatar: 'user1-avatar.png' },
        { name: 'User2', id: 2, avatar: 'user2-avatar.png' },
    ]);
    const { organizationId, messageId } = useParams()
    console.log("organization Id : ", organizationId, " messageId : ", messageId)
    const [comment, setComment] = useState('');
    const [commentBox, setCommentBox] = useState(false)
    const handleInputChange = (e) => {
        setComment(e.target.value);
    };
    // const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const handleShowComment = () => {
        setCommentBox(true)
    }
    // const openNav = () => {
    //     setIsOpen(true);
    // };

    // const closeNav = () => {
    //     setIsOpen(false);
    // };

    const copyLink = () => {
        navigator.clipboard.writeText(window.location.href)
            .then(() => {
                console.log("not working")
                toast.success('Copied!');
                setActiveCommentId(null);
            })
            .catch(() => {
                toast.error('Failed to copy the link');
            });
    };
    const [zoomedImage, setZoomedImage] = useState(null);

    const handleImageClick = (e) => {
        if (e.target.tagName === "IMG") {
            if (zoomedImage === e.target.src) {
                setZoomedImage(null); // Zoom out
            } else {
                setZoomedImage(e.target.src); // Zoom in
            }
        }
    };
    const sendMessage = () => {
        if (!content.trim()) return;
        const newComment = {
            organizationId, senderId: { // Include full sender details
                _id: senderId, // Replace `currentUser` with your logged-in user's data
                username: userName,

            }, scheduleId,
            content, createdDate: new Date().toISOString(), projectId

        }
        console.log("Sending new comment:", newComment);
        socket.emit('sendScheduleComment', newComment);
        setComments((prevComments) => {
            // Ensure prevComments is an array before spreading
            if (Array.isArray(prevComments)) {
                return [...prevComments, newComment];
            } else {
                return [newComment]; // Initialize as a new array with the new comment
            }
        });
        socket.on('receiveScheduleComment', (newMessage) => {
            console.log("Received new comment from socket:", newMessage);
        });

        console.log("Message sent:", { content });
        setMessage("");
        setContent("")
        setCommentBox(false)
    };
    useEffect(() => {
        // Create an abort controller for cleanup
        const fetchMessages = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/messageboard/${organizationId}/${messageId}`);
                setMessages(response.data);
                console.log("127", response.data)
            } catch (error) {
                // Check if error is not from the abort
                console.error(error.message)
                // setError(error.message);
            }
        };
        fetchMessages();

    }, [messageId, organizationId]);

    useEffect(() => {
        const fetchComments = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/organization/${organizationId}/${scheduleId}/scheduleComments`)
                setComments(response.data)
                console.log("Comments", response.data)
            } catch (error) {
                console.error(error.message)
            }
        }
        fetchComments()
        socket.on('receiveScheduleComment', (newMessage) => {
            setComments((prevMessages) => [...prevMessages, newMessage]);
        });
    }, [scheduleId, organizationId])
    const formats = [
        ['bold', 'italic', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ 'color': [] }, { 'background': [] }],
        ['blockquote', 'code-block'],
        ['link', 'image'],

    ];

    if (error) return <div>Error:</div>;
    function getInitials(name = "") {
        if (!name) return "?";
        const initials = name
            .split(" ")
            .map(part => part.charAt(0).toUpperCase())
            .join("");
        return initials;
    }
    const getColorFromId = (id) => {
        const colors = [
            '#1abc9c',
            '#e74c3c',
            '#3498db',
            '#9b59b6',
            '#f39c12',
            '#2ecc71',
            '#e67e22',
        ];

        if (!id) return colors[0];

        const hash = id.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);
        return colors[hash % colors.length];
    };
    console.log(userName)
    console.log(comments)
    const renderImagesWithActions = (htmlContent) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, "text/html");
        const images = doc.querySelectorAll("img");

        images.forEach((img) => {
            const wrapper = document.createElement("div");
            wrapper.className = "image-wrapper";

            const actions = document.createElement("div");
            actions.className = "image-actions";

            // Ensure actions are added when the image is loaded
            const addActions = () => {
                actions.innerHTML = `
                    <a href="${img.src}" target="_blank" class="view-full-size">View full-size</a>
                    <a href="${img.src}" download class="download">Download</a>
                `;
                wrapper.appendChild(img.cloneNode(true));
                wrapper.appendChild(actions);
                img.replaceWith(wrapper);
            };

            if (img.complete) {
                addActions(); // For already loaded images
            } else {
                img.addEventListener("load", addActions); // Wait for the image to load
            }
        });

        return { __html: doc.body.innerHTML };
    };
    const handleDeleteComment = (commentId) => {
        // Show confirmation dialog
        const isConfirmed = window.confirm("Are you sure you want to trash this comment?");
        if (isConfirmed) {
            setComments((prevComments) => prevComments.filter((comment) => comment._id !== commentId));
            // Emit socket event or make API call to delete the comment
            socket.emit('deleteScheduleComment', { organizationId, commentId, senderId, scheduleId });
        }
    };
    socket.on('scheduleCommentDeleted', ({ commentId }) => {

        setComments((prevComments) => {
            // Ensure the comment is removed from the state after server confirmation
            return prevComments.filter((comment) => comment._id !== commentId);
        });
    });
    const handleEditComment = (comment) => {
        setActiveCommentId(null);
        setContent(comment.content);
        setEditCommentId(comment._id);
    };



    const startEditing = (commentId) => {
        const updatedComments = comments.map((item) =>
            item._id === commentId ? { ...item, content } : item
        );

        // Emit the updateComment event
        socket.emit("updateScheduleComment", {
            organizationId: organizationId,
            commentId,
            senderId,
            content,
            scheduleId
        });

        setComments(updatedComments); // Update the comments list
        setEditCommentId(null); // Close the editor
        setContent(''); // Clear the editor content
    };


    const cancelEdit = () => {
        setEditCommentId(null); // Close the editor
        setContent(''); // Reset the editor content
    };

    console.log(editCommentId)
    console.log(comment)
    const handleBreadcrumbClick = (folderId) => {
        // Navigate to the clicked folder's URL
        navigate(`/${organizationId}/project/${projectId}/docsfiles/${folderId}`);
    };
    const handleBack = (e) => {
        e.preventDefault()
        // Navigate back to the project overview or the previous folder
        navigate(`/${organizationId}/project/${projectId}/docsfiles`)
    };
    return (
        <div className='campfire todo-contain'>
            <div className='campfire text-center d-block m-auto campfire card--head camp' style={{ width: '63vw', height: '6vh' }}>
                <div className='d-flex justify-content-center align-items-center mt-2 h-100'>
                    <div className="icon--div me-2">
                        <FontAwesomeIcon className='me-1' icon={faTh} />
                        <FontAwesomeIcon icon={faChevronDown} />
                    </div>
                    <Link onClick={e => handleBack(e)} className="fw-bold">{projectName}</Link>
                    <MdNavigateNext />

                    {/* <MdNavigateNext /> */}
                    <Link to="" className="fw-bold">{item?.title}</Link>
                </div>
            </div>
            <div className="d-flex justify-content-center align-items-center camp">
                <div className="text-center" style={{ width: '75vw' }}>
                    <div className="campfireBody">
                        <div className="card-body">
                            <div className="container " style={{ minHeight: '84vh' }}>

                                <div className="d-flex justify-content-between">

                                    <div className="mt-3"></div>
                                    <div className="text-center mt-3">
                                        <div id="mySidenav" className={`CampfireSidenav viewScheduleSide ${isOpen ? 'open' : ''} text-start`}>
                                            <span className="closebtn" onClick={closesideNav}>&times;</span>
                                            <div className="P-2">
                                                <Link to="#" className='sideBar text-decoration-none'>
                                                    <button className='me-2 sidebarBtn'><MdModeEdit /> </button>Edit
                                                </Link>
                                            </div>
                                            <div className="P-2">
                                                <Link to="#" className='sideBar text-decoration-none'>
                                                    <button className='me-2 sidebarBtn'><FaDownload /></button>Move
                                                </Link>
                                            </div>
                                            <div className="P-2">
                                                <Link to="#" className='sideBar text-decoration-none'>
                                                    <button className='me-2 sidebarBtn'><IoCopyOutline /></button>Copy
                                                </Link>
                                            </div>
                                            <div className="P-2">
                                                <Link to="#" className='sideBar text-decoration-none'>
                                                    <button className='me-2 sidebarBtn'><MdArchive /></button>Archive
                                                </Link>
                                            </div>
                                            <div className="P-2">
                                                <Link to="#" className='sideBar text-decoration-none'>
                                                    <button className='me-2 sidebarBtn'><FaRegTrashAlt /></button>Put in the trash
                                                </Link>
                                            </div>
                                            <div className="P-2">
                                                <Link to="#" className='sideBar text-decoration-none'>
                                                    <button className='me-2 sidebarBtn'><FaRegBookmark /></button>Bookmark
                                                </Link>
                                            </div>
                                            <div className="P-2">
                                                <Link to="#" className='sideBar text-decoration-none'>
                                                    <button className='me-2 sidebarBtn'><TbMessageOff /></button>Close comments
                                                </Link>
                                            </div>
                                            <div className="P-2">
                                                <Link to="#" className='sideBar text-decoration-none'>
                                                    <button className='me-2 sidebarBtn'><TbHandClick /></button>Dont Forget
                                                </Link>
                                            </div>
                                        </div>
                                        {/* <h3 className="mb-0 text-light">
                                            {messages?.title || 'TeamNex Project'}
                                        </h3> */}
                                    </div>
                                    <div className="text-end d-flex align-items-center">
                                        {/* <span>
                                            <button className='Notifybtn me-0'>Edit</button>
                                        </span> */}
                                        <div className="Notifybtn text-light" onClick={opensideNav}>
                                            <FontAwesomeIcon icon={faEllipsisH} />
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-center'>

                                    <div className="main-content text-light">
                                        {/* <p className='m-0'>
                                            <span>
                                                {messages.category?.emoji}
                                            </span>
                                            <span>
                                                {messages.category?.name}
                                            </span></p> */}
                                        <div className='mt-3 d-flex justifify-content-center flex-column align-items-center'>

                                            <div className='w-50'>

                                                <div className='d-flex justify-content-start  align-items-center view-schedule-gap'>
                                                    <span>
                                                        <div className='calender-stamp'>
                                                            <div className='t-color2'>{format(item.startDate, 'MMM')}</div>
                                                            <div className='px-3 bg-dark'>{format(item.startDate, 'dd')}</div>

                                                        </div>
                                                    </span>
                                                    <h3 className="text-light">{item.title || 'Untitled'}</h3>
                                                </div>
                                                <div className='d-flex justify-content-start align-items-center view-schedule-gap'>
                                                    <span className='fw-bold fs-5'>When</span>
                                                    {/* <span>{new Date.toDateString()}</span> */}
                                                    <span>{format(item.startDate, 'EEE, MMM dd')}</span>
                                                    {item && item.endTime && <span>{format(item.startTime, ' hh:mm a')} - {format(item.endTime, ' hh:mm a')}</span>}
                                                </div>

                                                <div className='text-light d-flex justify-content-start align-items-center view-schedule-gap'>
                                                    <span>
                                                        <span className='fw-bold fs-5'>With</span>

                                                    </span>
                                                    <div className="profileCircles d-flex justify-content-center align-items-center">

                                                        {item?.createdBy?.profilePhoto ? (
                                                            <>
                                                                <img
                                                                    src={`${process.env.REACT_APP_API_URL}${messages.sender.profilePhoto}`}
                                                                    alt={`${messages.sender.username}'s profile`}
                                                                    style={{ width: '30px', height: '30px', borderRadius: '50%', marginRight: '10px' }}
                                                                />

                                                            </>
                                                        ) : (
                                                            <span
                                                                className='me-2'
                                                                style={{
                                                                    width: '30px',
                                                                    height: '30px',
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    borderRadius: '50%',
                                                                    // backgroundColor: "red",
                                                                    backgroundColor: getColorFromId(item?.createdBy?._id),
                                                                    color: '#fff',
                                                                    marginRight: '10px',
                                                                    fontSize: '15px',
                                                                    fontWeight: '500'
                                                                }}
                                                            >
                                                                {getInitials(item.createdBy?.username)}
                                                            </span>
                                                        )
                                                        }
                                                        <span><p className='m-0'>{`${item.createdBy?.username}`}

                                                        </p></span>
                                                        {/* <p className='ms-1 text-decoration-none text-primary m-0'>{` Notified ${item?.assignedTo?.length} people`}</p> */}
                                                    </div>
                                                </div>
                                                {item.link &&
                                                    <div className='d-flex justify-content-start align-items-center view-schedule-gap'>
                                                        <span className='fw-bold fs-5'>Link</span>
                                                        {/* <span>{new Date.toDateString()}</span> */}
                                                        <span>
                                                            <a href={item.link} target='_blank' rel='noreferrer'>

                                                                {item?.link}
                                                            </a>
                                                        </span>
                                                        {/* {item && item.endTime && <span>{format(item.startTime, ' hh:mm a')} - {format(item.endTime, ' hh:mm a')}</span>} */}
                                                    </div>
                                                }
                                                <div className='d-flex justify-content-start align-items-center view-schedule-gap'>
                                                    <span className='text-secondary'>Added by</span>
                                                    <span><p className='m-0 text-secondary'>{`${item.createdBy?.username}`}</p></span>
                                                </div>
                                                {item?.notes &&
                                                    <div style={{ minHeight: '100px' }} className='d-flex justify-content-start align-items-center view-schedule-gap'>
                                                        <span className='fs-5 fw-bold'>Notes</span>
                                                        <span dangerouslySetInnerHTML={{ __html: item.notes }} className="t-color1 message-board-content " ></span>
                                                    </div>
                                                }
                                            </div>

                                        </div>

                                        <hr className="line" />

                                        {/* Display Comments */}
                                        <div className='d-flex justify-content-center'>

                                            <div className='comment-display-section w-75'>
                                                {comments && comments.length > 0 ? (
                                                    comments.map((comment, index) => (
                                                        <div key={index} className="comment-item text-light">

                                                            <div className={`d-flex position-relative ${editCommentId ? "" : ""}`}>
                                                                <span className='me-2 text-secondary'>
                                                                    {/* {new Date(comment.createdDate).toLocaleDateString('en-US', {
                                                                        month: 'long',
                                                                        day: 'numeric',
                                                                    })} */}
                                                                </span>
                                                                <div>

                                                                    <div className='d-flex'>

                                                                        <div className="profileCircles d-flex justify-content-center align-items-center">
                                                                            {comment?.senderId?.profilePhoto ? (
                                                                                <>
                                                                                    <img
                                                                                        src={`${process.env.REACT_APP_API_URL}${messages.sender.profilePhoto}`}
                                                                                        alt={`${messages.sender.username}'s profile`}
                                                                                        style={{ width: '30px', height: '30px', borderRadius: '50%', marginRight: '10px' }}
                                                                                    />

                                                                                </>
                                                                            ) : (
                                                                                <span
                                                                                    className='me-2'
                                                                                    style={{
                                                                                        width: '44px',
                                                                                        height: '44px',
                                                                                        display: 'flex',
                                                                                        justifyContent: 'center',
                                                                                        alignItems: 'center',
                                                                                        borderRadius: '50%',
                                                                                        // backgroundColor: "red",
                                                                                        backgroundColor: getColorFromId(comment.senderId?._id),
                                                                                        color: '#fff',
                                                                                        marginRight: '10px',
                                                                                        fontSize: '22px',
                                                                                        fontWeight: '500'
                                                                                    }}
                                                                                >
                                                                                    {getInitials(comment.senderId?.username)}
                                                                                </span>
                                                                            )
                                                                            }

                                                                        </div>
                                                                        {!editCommentId &&

                                                                            <p className="comment-author m-0">{comment?.senderId?.username || 'Anonymous'}</p>
                                                                        }
                                                                    </div>
                                                                    {!editCommentId &&
                                                                        <div dangerouslySetInnerHTML={renderImagesWithActions(comment.content)} className={`editor-content ${zoomedImage ? "zoom-enabled" : ""
                                                                            }
                                                                        t-color1 message-board-content text-start ms-5`} onClick={handleImageClick} ></div>
                                                                    }
                                                                </div>
                                                                {editCommentId === comment._id && (
                                                                    <div className="message-board-editor edit-comment-editor">
                                                                        <ReactQuill
                                                                            modules={{ toolbar: formats }}
                                                                            placeholder="Edit your comment..."
                                                                            value={content}
                                                                            onChange={setContent}
                                                                            style={{ minHeight: '100px' }}
                                                                        />
                                                                        <div className='d-flex align-items-end editCommentBtn' style={{ height: " 235px" }}>

                                                                            <button onClick={() => startEditing(comment._id)} className='save-draft me-3'>Save changes</button>
                                                                            <button onClick={cancelEdit} className='post-message'>Discard changes</button>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                <div className='me-auto'>

                                                                </div>
                                                                <div className="Notifybtn text-light border-0" onClick={() => openNav(comment._id)} style={{ height: "35px" }}>
                                                                    <FontAwesomeIcon icon={faEllipsisH} />
                                                                </div>
                                                                <div id="mySidenav" className={`CampfireSidenav commentShowSide ${activeCommentId === comment._id ? 'open' : ''} pt-5`}>
                                                                    <span className="closebtn" onClick={closeNav}>&times;</span>
                                                                    <div className="P-2">
                                                                        <Link to="#" className='sideBar text-decoration-none' onClick={(e) => e.preventDefault()}>
                                                                            <button className='me-2 sidebarBtn'>
                                                                                <TbHandClick />
                                                                            </button>Don't forget
                                                                        </Link>
                                                                    </div>
                                                                    <div className="P-2" onClick={copyLink}>
                                                                        <Link to="#" className='sideBar text-decoration-none' onClick={(e) => e.preventDefault()} >
                                                                            <button className='me-2 sidebarBtn'><IoIosLink /></button>Copy link
                                                                        </Link>
                                                                    </div>
                                                                    <div className="P-2">
                                                                        <Link to="#" className='sideBar text-decoration-none' onClick={(e) => e.preventDefault()}>
                                                                            <button className='me-2 sidebarBtn'><FaRegBookmark /></button>Bookmark
                                                                        </Link>
                                                                    </div>

                                                                    {senderId === comment.senderId._id &&
                                                                        <div className="P-2">
                                                                            <Link to="#" className='sideBar text-decoration-none' onClick={(e) => e.preventDefault()}>
                                                                                <div onClick={() => handleEditComment(comment)}>
                                                                                    <button className='me-2 sidebarBtn' ><MdModeEdit /></button>Edit
                                                                                </div>
                                                                            </Link>
                                                                        </div>}
                                                                    {senderId === comment.senderId._id &&
                                                                        <div className="P-2">
                                                                            <Link to="#" className='sideBar text-decoration-none' onClick={(e) => e.preventDefault()}>
                                                                                <div onClick={() => handleDeleteComment(comment._id)}>
                                                                                    <button className='me-2 sidebarBtn' >
                                                                                        <MdDelete />
                                                                                    </button>
                                                                                    Delete
                                                                                </div>
                                                                            </Link>
                                                                        </div>
                                                                    }
                                                                    <div className="P-2">
                                                                        <Link to="#" className='sideBar text-decoration-none' onClick={(e) => e.preventDefault()}>
                                                                            <button className='me-2 sidebarBtn'><IoMdPeople /></button>Notified 1 people
                                                                        </Link>
                                                                    </div>

                                                                </div>

                                                            </div>
                                                            <div>

                                                                <hr className="line" />
                                                            </div>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <p className="text-light"></p>
                                                )}
                                            </div>
                                        </div>

                                        {/* Comment Section */}
                                        <div div className="comment-section w-100" style={{ minHeight: '100px' }}>
                                            {!commentBox && (
                                                <div className="add-comment-placeholder d-flex justify-content-center align-items-center w-100" onClick={handleShowComment} style={{ cursor: 'pointer' }}>
                                                    <div className='d-flex align-items-center'>

                                                        <div className="profileCircles d-flex justify-content-center align-items-center">
                                                            {messages?.sender?.profilePhoto ? (
                                                                <>
                                                                    <img
                                                                        src={`${process.env.REACT_APP_API_URL}${messages.sender.profilePhoto}`}
                                                                        alt={`${messages.sender.username}'s profile`}
                                                                        style={{ width: '30px', height: '30px', borderRadius: '50%', marginRight: '10px' }}
                                                                    />

                                                                </>
                                                            ) : (
                                                                <span
                                                                    className='me-2'
                                                                    style={{
                                                                        width: '43px',
                                                                        height: '43px',
                                                                        display: 'flex',
                                                                        justifyContent: 'center',
                                                                        alignItems: 'center',
                                                                        borderRadius: '50%',
                                                                        // backgroundColor: "red",
                                                                        backgroundColor: getColorFromId(userName),
                                                                        color: '#fff',
                                                                        marginRight: '10px',
                                                                        fontSize: '20px',
                                                                        fontWeight: '500'
                                                                    }}
                                                                >
                                                                    {getInitials(userName)}
                                                                </span>
                                                            )
                                                            }

                                                        </div>
                                                        <p className="text-secondary m-0 fs-5">Add a comment here...</p>
                                                    </div>

                                                </div>
                                            )}
                                            {commentBox && (
                                                <form form onSubmit={(e) => { e.preventDefault(); sendMessage(); }}>
                                                    <div className='message-board-editor'>
                                                        <ReactQuill
                                                            modules={{ toolbar: formats }}
                                                            placeholder='Add a comment...'
                                                            value={content}
                                                            onChange={setContent}
                                                            style={{ minHeight: '100px' }}
                                                        />
                                                    </div>
                                                    <div className='text-start mt-2'>
                                                        <button className='commentBtn' type="submit" onClick={sendMessage}>Add This Comment</button>
                                                    </div>
                                                </form>
                                            )

                                            }

                                        </div>

                                    </div>
                                </div>
                                <hr className="line" />
                                {/* <div className='d-flex justify-content-center align-items-center mt-4'>
                                    <div>
                                        <p className='fw-bold'>Subscribers</p>
                                        <div className='d-flex align-items-center'>
                                            <span className='profile-circle'>PK</span>
                                            <span className='profile-circle'>SK</span>
                                            <button className='Notifybtn ms-2'>Add/remove people...</button>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <p className='mt-2'>You’re subscribed</p>
                                    <button className='unsubscribeBtn'>Unsubscribe me</button>
                                </div> */}

                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div >

    );
};

export default ViewSchedule;
