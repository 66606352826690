import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';


import { Rings } from 'react-loader-spinner';

const BasecampAutHandler = ({ setIsSignedIn, isSignedIn, onSignIn }) => {

    const navigate = useNavigate();
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    const email = urlParams.get("state");
    useEffect(() => {
        // Delay the API call by 10 seconds
        const timeoutId = setTimeout(() => {

            const ExchangeCode = async () => {
                try {
                    console.log("called url", `${process.env.REACT_APP_API_URL}/api/basecamp/auth/store_access_token?code=${code}&email=${email}`);
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/basecamp/auth/store_access_token?code=${code}&email=${email}`,);
                    localStorage.setItem("token", response.data.token);

                    console.log(response.data);
                    localStorage.setItem('token', response.data.token);
                    localStorage.setItem('userData', JSON.stringify(response.data));
                    localStorage.setItem('isLoggedIn', response.data.token)
                    localStorage.setItem('currentOrg', response.data.user.organizationId)
                    localStorage.setItem('base_token', response.data.user.base_access_token)
                    setIsSignedIn(true);
                    onSignIn(response.data.token);
                    navigate(`/${response.data.user.organizationId}`);

                } catch (error) {
                    console.error('Error verifying invitation:', error.response?.data?.message || error.message);
                    alert('Failed to process Please contact support.');
                    navigate('/');
                }
            };

            ExchangeCode();
        }, 6000); // 10 seconds delay

        // Clean up the timeout if the component unmounts before the API call is triggered
        return () => clearTimeout(timeoutId);
    }, [navigate]);
    console.log('isSignedIn', isSignedIn);
    return <div className='d-flex justify-content-center align-items-center flex-column' style={{ height: '100vh' }}>
        <div>
            <Rings
                visible={true}
                height="80"
                width="80"
                color={"green"}
                ariaLabel="rings-loading"
            />
        </div>
        <div>
            <h4>
                Authenticating with Basecamp, please wait...
            </h4>
        </div>
    </div>;
};

export default BasecampAutHandler;
