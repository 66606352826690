import { React, useState, useEffect } from 'react'
import { faTh, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactQuill from 'react-quill';
import { jwtDecode } from 'jwt-decode';

const TaskList = () => {

    const [commentBox, setCommentBox] = useState(false);
    const [commentContent, setCommentContent] = useState({});
    const [content, setContent] = useState('');
    const [data, setData] = useState(null)
    const [error, setError] = useState(null);
    const [messages, setMessages] = useState([]);

    console.log(data)
    useEffect(() => {
        const savedToken = localStorage.getItem('token');
        if (savedToken) {
            const decodedToken = jwtDecode(savedToken);
            setData(decodedToken)
            // Check if the token is expired      
        } else {
            console.log('No valid userData found in localStorage');
        }
    }, []);
    const userName = data?.username
    const handleShowComment = () => {
        setCommentBox(true)
    }
    function getInitials(name = "") {
        if (!name) return "?";
        const initials = name
            .split(" ")
            .map(part => part.charAt(0).toUpperCase())
            .join("");
        return initials;
    }

    const getColorFromId = (id) => {
        const colors = [
            '#1abc9c',
            '#e74c3c',
            '#3498db',
            '#9b59b6',
            '#f39c12',
            '#2ecc71',
            '#e67e22',
        ];

        if (!id) return colors[0];

        const hash = id.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);
        return colors[hash % colors.length];
    };
    const formats = [
        [{ header: '1' }, { header: '2' }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['bold', 'italic', 'underline'],
        ['link', 'image'], // Image upload button
        ['clean'], // Clear button
    ];
    const handleCommentSubmit = async (messageId) => {
        const content = commentContent[messageId];

        // Validate the comment content
        if (typeof content !== 'string' || !content.trim()) {
            console.error('Comment is not defined or is not a valid string');
            return;
        }

        try {
            // Send the comment to the backend
            const response = await fetch(`${process.env.REACT_APP_API_URL}/messages/${messageId}/comment`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ content }), // Pass the comment content
            });

            // Check for errors in the response
            // if (!response.ok) {
            //     const errorMessage = await response.text();
            //     // console.error('Failed to submit comment:', response.status, errorMessage);
            //     // throw new Error(`Failed to submit comment: ${response.statusText}`);
            // }

            // Update the message with the new comment data
            const updatedComment = await response.json();
            setMessages((prevMessages) =>
                prevMessages.map((msg) => (msg._id === messageId ? { ...msg, comments: [...msg.comments, updatedComment] } : msg))
            );

            // Clear the comment input field after successful submission
            setCommentContent((prev) => ({ ...prev, [messageId]: '' }));
        } catch (error) {
            console.error('Error submitting comment:', error);
            setError(error.message);
        }
    };
    return (
        <>
            <div className='todopage'>
                <div className="campfire todo-contain">
                    <div className="text-center d-block m-auto campfire card--head camp" style={{ width: '65vw' }}>
                        <div className='d-flex justify-content-center align-items-center mt-2 h-100'>
                            <button className="icon--button me-2">
                                <FontAwesomeIcon className='me-1' icon={faTh} />
                                <FontAwesomeIcon icon={faChevronDown} />
                            </button>
                            <span className="fw-bold text-primary text-decoration-underline" style={{ cursor: 'pointer' }}>
                                Demo todo
                            </span>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                        <div className="d-flex justify-content-center align-items-center camp">
                            <div className="text-center" style={{ width: '75vw' }}>
                                <div className="campfireBody">
                                    <div className="card-body pb-2">
                                        <div className="container " style={{ minHeight: '84vh' }}>
                                            <div className="d-flex justify-content-between">
                                                <div className="mt-3"></div> <div className="text-center mt-3">
                                                    {/* <h3 className="mb-0 text-light">
                                                   {messages?.title || 'TeamNex Project'}
                                               </h3> */}
                                                </div>
                                                <div className="text-end d-flex align-items-center">
                                                    <span>
                                                        <button className='Notifybtn me-0'>Edit</button>
                                                    </span>
                                                    {/* <div className="Notifybtn text-light" onClick={openNav}>
                                                   <FontAwesomeIcon icon={faEllipsisH} />
                                               </div> */}
                                                </div>
                                            </div>
                                            <div className=''>
                                                <div className='text-start ps-4'>
                                                    <p>0/4 Complete</p>
                                                    <h2>Project Date</h2>
                                                </div>
                                                <hr className="line" />

                                                <div className='mt-5 mb-5'>
                                                    <div className='d-flex todoProject text-start ps-5'>
                                                        <input type='checkbox' className='projectName me-2' />
                                                        <p>projectName todo side bar idss</p>
                                                    </div>
                                                    <button className='d-flex addtask bg-transparent justify-content-center ms-5 rounded-5 p-1'>Add a To-do</button>
                                                </div>
                                                <div className='d-flex justify-content-center'>
                                                    <hr className="lin" />
                                                </div>


                                                {/* {/ Comment Section /} */}
                                                <div className="comment-section w-100" style={{ minHeight: '100px' }}>
                                                    {!commentBox && (
                                                        <div className="add-comment-placeholder" onClick={handleShowComment} style={{ cursor: 'pointer' }}>
                                                            <div className='d-flex align-items-center'>

                                                                <div className="profileCircles col-lg-4 d-flex justify-content-end">
                                                                    {messages?.sender?.profilePhoto ? (
                                                                        <>
                                                                            <img
                                                                                src={`${process.env.REACT_APP_API_URL}${messages.sender.profilePhoto}`}
                                                                                alt={`${messages.sender.username}'s profile`}
                                                                                style={{ width: '30px', height: '30px', borderRadius: '50%', marginRight: '10px' }}
                                                                            />

                                                                        </>
                                                                    ) : (
                                                                        <span
                                                                            className='me-2'
                                                                            style={{
                                                                                width: '43px',
                                                                                height: '43px',
                                                                                display: 'flex',
                                                                                justifyContent: 'center',
                                                                                alignItems: 'center',
                                                                                borderRadius: '50%',
                                                                                // backgroundColor: "red",
                                                                                backgroundColor: getColorFromId(userName),
                                                                                color: '#fff',
                                                                                marginRight: '10px',
                                                                                fontSize: '20px',
                                                                                fontWeight: '500'
                                                                            }}
                                                                        >
                                                                            {getInitials(userName)}
                                                                        </span>
                                                                    )
                                                                    }

                                                                </div>
                                                                <p className="text-secondary m-0 fs-5 ms-3">Add a comment here...</p>
                                                            </div>

                                                        </div>
                                                    )}
                                                    {commentBox && (


                                                        <form form onSubmit={(e) => { e.preventDefault(); handleCommentSubmit(); }}>
                                                            <div className='message-board-editor'>
                                                                <ReactQuill
                                                                    modules={{ toolbar: formats }}
                                                                    placeholder='Add a comment...'
                                                                    value={content}
                                                                    onChange={setContent}
                                                                    style={{ minHeight: '100px' }}
                                                                />
                                                            </div>
                                                            <div className='text-start mt-2'>
                                                                <button className='commentBtn' type="submit">Add This Comment</button>
                                                            </div>
                                                        </form>
                                                    )

                                                    }

                                                </div>


                                            </div>
                                            <div className='d-flex justify-content-center'>
                                                <hr className="lin" />
                                            </div>
                                            <div className='col-lg-10 d-flex justify-content-center mb-5'>
                                                <div>
                                                    <p className='fw-bold text-start'>Subscribers</p>
                                                    <div className='d-flex align-items-center'>
                                                        <span className='profile-circle'>PK</span>
                                                        <span className='profile-circle'>SK</span>
                                                        <button className='Notifybtn ms-2'>Add/remove people...</button>
                                                    </div>
                                                    <p className='mt-2 text-start'>You’re subscribed</p>
                                                    <div className='d-flex justify-content-start'>
                                                        <button className='subscribeBtn rounded-4'>Unsubscribe me</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TaskList

