// import { createContext, useContext, useEffect, useState } from "react";
// import { messaging } from "../components/FireBase/firebase"; // Ensure correct Firebase import
// import { getToken, onMessage } from "firebase/messaging";

// export const FcmContext = createContext();

// export const FcmProvider = ({ children }) => {
//     const [fcmToken, setFcmToken] = useState(localStorage.getItem("fcmToken") || "");

//     useEffect(() => {
//         const requestPermission = async () => {
//             try {
//                 const permission = await Notification.requestPermission();
//                 if (permission === "granted") {
//                     const currentToken = await getToken(messaging, {
//                         vapidKey: "BDbKP12RRP1f2EVuQOSRl7mgov7gHjOQVtpjI1r6NEgtUYEhr-0wrx9SMyNoqluwLwKAahT2X2moZnXXdz9_tKs",
//                     });

//                     if (currentToken) {
//                         setFcmToken(currentToken);
//                         localStorage.setItem("fcmToken", currentToken);
//                     } else {
//                         console.log("No FCM token received.");
//                     }
//                 } else {
//                     console.log("Notification permission denied.");
//                 }
//             } catch (error) {
//                 console.error("Error getting permission for notifications", error);
//             }
//         };

//         requestPermission();
//     }, []);

//     // useEffect(() => {
//     //     const unsubscribe = onMessage(messaging, (payload) => {
//     //         console.log("🔥 Message received:", payload);
//     //         // Handle notification (update state, show toast, etc.)
//     //     });

//     //     return () => unsubscribe();
//     // }, []);

//     return <FcmContext.Provider value={fcmToken}>{children}</FcmContext.Provider>;
// };

// export const useFcmToken = () => useContext(FcmContext);
