import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';


const YourAssignments = ({ userId }) => {

    const [assignment, setAssignment] = useState([]);
    const { organizationId } = useParams();

    //  console.log(userId);

    const YourAssignment = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/organization/${organizationId}/user/${userId}/your-assignment`)
            setAssignment(response.data)
            console.log(response.data)
        } catch (error) {
            console.error("Error fetching assignments:", error);
        }
    }
    // useEffect(() => {
    //     YourAssignment();
    // }, [userId, organizationId]);


    return (
        <>
            <div className="card card1 schedule-assignment">
                <div className="card-header  border-0 text-center">
                    <div className="assignments-header">
                        <span>YOUR ASSIGNMENTS</span>
                    </div>
                </div>

                <div className="card-body  mt-3">
                    <p className='text-center'>Stuff due soon and recently assigned - <Link>see all</Link> </p>
                    {assignment.length > 0 ? (
                        assignment.map((task) => (
                            <div key={task._id}>
                                <div className='d-flex'>
                                    <span>
                                        <input type='checkbox' className=' d-inline-flex input_chrck_box form-check-input secondary-class me-2 d-flex self-align-end' />
                                    </span>
                                    <div className=''>

                                        <span className='m-0 p-0 '> <Link to={{}} className='t-color1 text-decoration-none'>{task.task_name}</Link> </span>
                                        <span className='text-secondary'>{task.assigned_to.username}</span>
                                        <div className='d-flex'>
                                            <p className='text-secondary'>{task.todolistId.todo_title} &nbsp;</p>
                                            <p className='text-secondary'>{task.todolistId.projectid.name}</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        ))
                    ) : (
                        <div className='text-center'>
                            <FontAwesomeIcon icon={faCircleCheck} className="home-shedule" size="2x" />
                            <p className="mt-3 text-secondary">
                                You don’t have any assignments right now. To-dos and cards assigned to you will show up here.
                            </p>
                        </div>
                    )
                    }



                </div>

            </div>
        </>
    )
}

export default YourAssignments