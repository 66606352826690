import { useState } from "react";
import { Dropdown } from "react-bootstrap";

import React from 'react'
import { Rings } from "react-loader-spinner";

const OrganizationDropdown = ({ organizations, isLoading, onSelect }) => {
    // console.log("org", organizations)
    return (
        <Dropdown.Menu show className="w-100 org-swich-drop secondary-class p-3">
            {organizations.length > 1 && <h6>Switch To :</h6>}
            {isLoading ? (
                <div className="d-flex justify-content-center p-3">
                    <Rings visible={true} height="50" width="50" color="var(--theme-color)" ariaLabel="rings-loading" />
                </div>
            ) : organizations.length > 0 ? (
                organizations.map((org) => (
                    <Dropdown.Item key={org.id} onClick={() => {
                        if (organizations.length > 1) {
                            console.log("Selected Org:", org); // ✅ Debugging: Logs when an org is clicked
                            onSelect(org);
                        }
                    }} className="text-decoration-none fw-bold org-swich-drop-item"
                        disabled={organizations.length === 1}
                    >
                        {org.name}
                    </Dropdown.Item>
                ))
            ) : (
                <Dropdown.Item disabled>No Organizations Found</Dropdown.Item>
            )}
        </Dropdown.Menu>
    )
}

export default OrganizationDropdown
