import { faMessage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Link, useNavigate } from "react-router-dom";
import { Rings } from "react-loader-spinner";

const animatedComponents = makeAnimated();


const PingsDropDown = ({ pingUsers, handleCreate, formatOptionLabel, organizationId, userId, selectedUsers, setSelectedUsers }) => {
    const [show, setShow] = useState(false);
    const [loadingChats, setLoadingChats] = useState(false);
    const [pingLoading, setPingLoading] = useState(false)
    const navigate = useNavigate()
    const [chats, setChats] = useState([]);
    // console.log("pingUsers", pingUsers)
    const dropdownRef = useRef(null);

    // Function to toggle the dropdown
    const toggleDropdown = () => setShow((prev) => !prev);

    // Function to close dropdown on outside click
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShow(false);
            }
        };

        if (show) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [show]);

    // Fetch chats when organizationId and userId are available
    useEffect(() => {
        if (!organizationId || !userId) return;

        setLoadingChats(true);

        const fetchUserChats = async () => {
            try {
                const res = await axios.get(
                    `${process.env.REACT_APP_API_URL}/api/organization/${organizationId}/${userId}/getChats`
                );
                setChats(res.data);
            } catch (err) {
                console.error("Error fetching chats:", err);
            } finally {
                setLoadingChats(false);
            }
        };

        fetchUserChats();
    }, [organizationId, userId]);
    const handlePing = async () => {
        if (selectedUsers.length === 0) {
            alert("Please select at least one user.");
            return;
        }
        const token = localStorage.getItem('token');
        if (!token) {
            console.error('Token is undefined or not found in localStorage');
            return;
        }
        try {
            setPingLoading(true)
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/organization/${organizationId}/createChat`,
                {
                    userIds: selectedUsers.map((user) => user.value),
                    chatName: selectedUsers.length > 1 ? "New Group" : undefined,
                },
                {
                    headers: { Authorization: `Bearer ${token}` }, // Include token
                }
            );

            console.log("Chat Created:", response.data);

            // Navigate to chat
            if (response.data && response.data._id) {
                navigate(`${organizationId}/chat/${response.data._id}`, {
                    state: {
                        userName:
                            selectedUsers.length > 1
                                ? "defaultName"
                                : selectedUsers[0]?.name,
                        userProfilePhoto:
                            selectedUsers.length > 1 ? null : selectedUsers[0].profilePhoto,

                    },
                });
            }
        } catch (error) {
            console.error("Error creating chat:", error);
            alert("Failed to create chat. Please try again.");
        } finally {
            setPingLoading(false)
            setShow(false)
            setSelectedUsers([])
        }
    };
    const getInitials = (name) => {
        const nameArray = name.split(' ');
        const initials = nameArray.map(n => n.charAt(0)).join('').toUpperCase();
        return initials;
    };
    const getColorFromId = (id) => {
        const colors = [
            '#1abc9c',
            '#e74c3c',
            '#3498db',
            '#9b59b6',
            '#f39c12',
            '#2ecc71',
            '#e67e22',
        ];

        if (!id) return colors[0];

        const hash = id.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);
        return colors[hash % colors.length];
    };
    return (
        <div className="PingsDw" ref={dropdownRef}>
            {/* Pings Dropdown Button */}
            <button className="PingsDw p-0 text-decoration-none btn btn-link" onClick={toggleDropdown}>
                <div className="demo-theme-test mx-1 fw-bold Pingsfl">
                    <FontAwesomeIcon icon={faMessage} /> Pings
                </div>
            </button>

            {/* Dropdown Menu */}
            {show && (
                <div className="PingsDw Pingshw dropdown-menu show position-absolute" style={{ display: "block" }}>
                    <div className="p-3">
                        {/* User Selection */}
                        <div className="d-flex ">
                            <div className="w-100">
                                <Select
                                    closeMenuOnSelect={false}
                                    className="PingsText text-white cursor-pointer"
                                    components={animatedComponents}
                                    placeholder="Start a private chat with..."
                                    isMulti
                                    noOptionsMessage={() => "No users found"}
                                    options={pingUsers}
                                    // getOptionValue={(option) => option._id}
                                    onChange={(selected) => {
                                        console.log("Dropdown Selection:", selected);
                                        setSelectedUsers(selected);
                                    }}
                                    formatOptionLabel={formatOptionLabel}
                                    value={selectedUsers}
                                />
                            </div>
                            {selectedUsers.length > 0 &&
                                <button type="button" className="pingBtn t-color2" onClick={handlePing} disabled={pingLoading}>
                                    {pingLoading ? `just a sec...` : `Pingem`}
                                </button>
                            }
                        </div>
                    </div>

                    {/* Chat List */}
                    <div className="ms-3 p-3 pingsDot h-100 row d-flex flex-wrap justify-content-center t-color1 gap-2" style={{ width: "95%" }}>
                        {loadingChats ? (
                            <div className="w-100 d-flex justify-content-center">
                                <Rings
                                    visible={true}
                                    height="80"
                                    width="80"
                                    color="var(--theme-color)"
                                    ariaLabel="rings-loading"
                                    className="d-flex w-100 justify-content-center align-items-center"
                                />
                            </div>
                        ) : chats.length > 0 ? (
                            chats.map((chat) => {
                                if (chat.isGroupChat) {
                                    const groupUsers = chat.users.slice(0, 3); // Get the first 3 users

                                    return (
                                        <Link to={`${organizationId}/chat/${chat._id}`} className="text-decoration-none col-2 t-color1" onClick={toggleDropdown}>
                                            <div key={chat._id} className="chat-item d-flex flex-column justify-content-center align-items-center">
                                                {/* Group Chat Avatar */}
                                                {/* <div className="group-avatar">
                                                    {groupUsers.map((user, index) => (
                                                        <div
                                                            key={user._id}
                                                            className="avatar-segment"
                                                            style={{
                                                                // position: "absolute",
                                                                // top: index === 0 ? "10%" : index === 1 ? "50%" : "30%",
                                                                // left: index === 0 ? "30%" : index === 1 ? "60%" : "10%",
                                                                // transform: "translate(-50%, -50%)",
                                                                width: "30px",
                                                                height: "30px",
                                                                borderRadius: "50%",
                                                                backgroundColor: user.profilePhoto ? "transparent" : getColorFromId(user._id),
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                color: "#fff",
                                                                overflow: "hidden",
                                                            }}
                                                        >
                                                            {user.profilePhoto ? (
                                                                <img
                                                                    src={`${process.env.REACT_APP_API_URL}${user.profilePhoto}`}
                                                                    alt="User"
                                                                    style={{
                                                                        width: "100%",
                                                                        height: "100%",
                                                                        borderRadius: "50%",
                                                                    }}
                                                                />
                                                            ) : (
                                                                <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                                                                    {getInitials(user.username)}
                                                                </span>
                                                            )}
                                                        </div>
                                                    ))}
                                                </div> */}
                                                <div className="group-avatar" style={{ position: "relative", width: "60px", height: "60px" }}>
                                                    {groupUsers.slice(0, 3).map((user, index) => {
                                                        const positions = [
                                                            { top: "0%", left: "50%", transform: "translate(-50%, 0)" }, // Top avatar
                                                            { top: "60%", left: "20%", transform: "translate(-50%, 0)" }, // Bottom-left avatar
                                                            { top: "60%", left: "80%", transform: "translate(-50%, 0)" }, // Bottom-right avatar
                                                        ];

                                                        return (
                                                            <div
                                                                key={user._id}
                                                                className="avatar-segment"
                                                                style={{
                                                                    position: "absolute",
                                                                    width: "30px",
                                                                    height: "30px",
                                                                    borderRadius: "50%",
                                                                    backgroundColor: user.profilePhoto ? "transparent" : getColorFromId(user._id),
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                    color: "#fff",
                                                                    overflow: "hidden",
                                                                    ...positions[index], // Apply dynamic positioning
                                                                }}
                                                            >
                                                                {user.profilePhoto ? (
                                                                    <img
                                                                        src={`${process.env.REACT_APP_API_URL}${user.profilePhoto}`}
                                                                        alt="User"
                                                                        style={{
                                                                            width: "100%",
                                                                            height: "100%",
                                                                            borderRadius: "50%",
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                                                                        {getInitials(user.username)}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        );
                                                    })}
                                                </div>

                                                {/* Group Chat Info */}
                                                <div className="mt-2">
                                                    {chat?.chatName ? (
                                                        <p className="m-0 text-center">{chat.chatName}</p>

                                                    ) : (
                                                        <p className="m-0 text-center">Group Chat</p>
                                                    )}
                                                    {/* <p>Group Chat</p> */}
                                                </div>
                                                <div>
                                                    <span className="fw-thin">{groupUsers.length} Members</span>
                                                </div>
                                            </div>
                                        </Link>

                                    );
                                } else {
                                    const otherUser = chat.users.find((user) => user._id !== userId);
                                    return otherUser ? (
                                        <Link to={`${organizationId}/chat/${chat._id}`} className="text-decoration-none col-2 t-color1" onClick={toggleDropdown}>
                                            <div key={chat._id} className="chat-item d-flex flex-column justify-content-center align-items-center">
                                                {otherUser.profilePhoto ? (
                                                    <img
                                                        src={`${process.env.REACT_APP_API_URL}${otherUser.profilePhoto}`}
                                                        alt="Profile"
                                                        style={{
                                                            width: '50px',
                                                            height: '50px',
                                                            borderRadius: '50%',
                                                        }}
                                                        className="d-flex justify-content-center align-items-center"
                                                    />
                                                ) : (
                                                    <span style={{
                                                        width: '50px',
                                                        height: '50px',
                                                        borderRadius: '50%',
                                                        backgroundColor: getColorFromId(otherUser._id),

                                                        fontSize: '16px',
                                                    }}
                                                        className="t-color1 d-flex justify-content-center align-items-center"
                                                    >


                                                        {getInitials(otherUser.username)}
                                                    </span>
                                                )}
                                                <div>
                                                    <p className="fs-5 text-center">{otherUser.username}</p>

                                                </div>
                                            </div>
                                        </Link>

                                    ) : null;
                                }
                            })
                        ) : (
                            <p className="t-color1">
                                Pings are private chats with one or more people. Start your first conversation by typing someone’s name.
                            </p>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default PingsDropDown;
