// src/utils/api.js
import axios from 'axios';

const getCookie = (name) => {
    const cookies = document.cookie.split("; ");
    for (let cookie of cookies) {
        const [key, value] = cookie.split("=");
        if (key === name) return decodeURIComponent(value);
    }
    return null;
};
export const fetchProject = async ({ id, organizationId, token, signout }) => {
    const authToken = getCookie("authToken");
    if (!token) {
        throw new Error('No token found. Please log in.');
    }

    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/project/${id}`,
            {
                params: { organizationId }, // Query parameters
                headers: {
                    Authorization: `Bearer ${token}`, // Authorization header
                    'C-Auth-Token': authToken
                },
                withCredentials: true,
            }
        );

        // console.log(response.data);
        return response.data.project; // Return the project data
    } catch (err) {
        console.error('Error in fetchProject:', err);
        // console.log(err.response.status)
        if (err.response?.status === 401) {
            // Handle server response errors
            if (signout) {
                signout()
            }
            throw new Error(err.response.data.message || 'Failed to load project');
        } else {
            // Handle other errors (e.g., network errors)
            throw new Error(err.message || 'Error fetching project');
        }
    }
};
